import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReactiveFormConfig, RxFormBuilder, RxwebValidators } from '@rxweb/reactive-form-validators';
import { Observable } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { Customer } from 'src/app/models/customer';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-select-customer',
  templateUrl: './select-customer.component.html',
  styleUrls: ['./select-customer.component.scss']
})
export class SelectCustomerComponent implements OnInit {
  form: FormGroup;
  customer: Customer;
  customerList: Customer[];
  filteredOptions: Observable<string[]>;
  terminalMode: string;


  constructor(
    private fb: RxFormBuilder,
    private customerService: CustomerService,
    private dialogRef: MatDialogRef<SelectCustomerComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    if (data && data.mode) this.terminalMode = data.mode;
    if (data.customer) {
      this.customer = data.customer;
      this.customer.id = 1; //this just serves as meta data for the check when the dialog is closed, to see if a customer is new or existing
    }
  }

  ngOnInit(): void {
    ReactiveFormConfig.set({
      "validationMessage": {
        "required": "This field is required",
        "minLength": "LPO should be at least 10 digits"
      }
    });

    this.form = this.fb.group({
      selectCustomer: ['', RxwebValidators.required()],
      lpo: ['', RxwebValidators.minLength({ value: 10 })]
    });
    if (this.customer) this.form.controls['selectCustomer'].setValue(this.customer);
    this.form.controls['selectCustomer'].valueChanges.pipe(
      debounceTime(200),
      switchMap(value => this.customerService.getCustomers(0, 100, 'firstName', 'asc', value)))
      .subscribe(data => {
        this.customerList = data.data
      });



  }

  select() {
    if (this.form.valid) {
      this.customer = this.form.controls['selectCustomer'].value;
      if (this.terminalMode === 'privilege') this.customer.lpo = this.form.controls['lpo'].value;
      console.log(`selected ${this.customer.firstName}`);
      this.dialogRef.close(this.customer);
    }
  }

  close() {
    this.dialogRef.close();
  }

  createNewCustomer() {
    this.dialogRef.close('new');
  }

  displayFn(c: Customer): string {
    if (!c) return;
    const name = `${c.firstName} ${c.lastName}`;
    return name;
  }


}
