import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
  MatSnackBarRef
} from '@angular/material/snack-bar';


@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  snackBarConfig: MatSnackBarConfig;
  snackBarRef: MatSnackBarRef<any>;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  snackBarAutoHide = '1500';

  constructor(private snackBar: MatSnackBar) { }

  showSuccessMessage(text: string) {
    let config = new MatSnackBarConfig();
    // config.panelClass = 'mat-snackbar-success';
    config.duration = 1000;
    this.snackBar.open(text, undefined, config);
  }

  showErrorMessage(text: string) {
    let config = new MatSnackBarConfig();
    config.panelClass = 'mat-snackbar-error';
    config.duration = 2000;
    this.snackBar.open(text, undefined, config);
  }

  showLongErrorMessage(text: string) {
    let config = new MatSnackBarConfig();
    config.panelClass = 'mat-snackbar-error';
    config.duration = 5000;
    this.snackBar.open(text, undefined, config);
  }
}
