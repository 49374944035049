import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonLoadingComponent} from '../shared/components/button-loading/button-loading.component';
import { HeaderComponent } from '../shared/header/header.component';
import { FooterComponent } from '../shared/footer/footer.component';


const components = [
  ButtonLoadingComponent,
  HeaderComponent,
  FooterComponent
]

@NgModule({
  declarations: components,
  imports: [
    CommonModule
  ],
  exports: components
})
export class SharedComponentsModule { }
