<h2 mat-dialog-title>{{title}}</h2>

<div fxLayout="column" [formGroup]="form">

  <mat-form-field>
    <input matInput placeholder="Name" formControlName="name" required>
    <mat-error *ngFor="let errorMessage of form.controls.name['errorMessages']">
      {{errorMessage}}
    </mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="!customProduct">
    <input matInput placeholder="Description" formControlName="description">
  </mat-form-field>

  <mat-form-field *ngIf="!customProduct">
    <mat-select formControlName="categoryName" name="categoryName" placeholder="Category"
      (selectionChange)="categoryChange()">
      <mat-option *ngFor="let c of categoryList" [value]="c.name">{{c.name}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-select formControlName="taxCodes" name="taxCodes" placeholder="Tax Code" (selectionChange)="taxesChanged()"
      [compareWith]="compareFunction" multiple required>
      <mat-option *ngFor="let tax of taxList" [value]="tax">{{tax.name}}</mat-option>
    </mat-select>
    <mat-error *ngFor="let errorMessage of form.controls.taxCodes['errorMessages']">
      {{errorMessage}}
    </mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="!customProduct || showRRP">
    <input matInput placeholder="Product Code" formControlName="productCode">
    <mat-error *ngFor="let errorMessage of form.controls.productCode['errorMessages']">
      {{errorMessage}}
    </mat-error>
  </mat-form-field>
  <div fxLayout="row" fxLayoutAlign="stretch center">
    <div fxFlex="40" fxLayoutAlign="start center">
      <mat-form-field>
        <input matInput type="number" placeholder="Sale Price" formControlName="price"
          (keyup)="updateSalesVatPrice($event)" required>
        <mat-error *ngFor="let errorMessage of form.controls.price['errorMessages']">
          {{errorMessage}}
        </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="40" fxFlex fxLayoutAlign="end center">
      <mat-form-field>
        <input matInput type="number" placeholder="Sale Price (VAT inclusive)" formControlName="priceVat"
          (keyup)="updateSalesPrice($event)">
      </mat-form-field>
    </div>
  </div>


  <mat-form-field *ngIf="!customProduct">
    <input matInput type="number" placeholder="Cost" formControlName="cost">
  </mat-form-field>

  <mat-form-field>
    <input matInput type="number" placeholder="Quantity" formControlName="quantity" (keyup)="updateFinalSalePrice()">
  </mat-form-field>





  <mat-form-field *ngIf="showRRP">
    <input matInput type="number" placeholder="RRP (VAT inclusive)" formControlName="rrp"
      (keyup)="updateFinalSalePrice()">
    <mat-error *ngFor="let errorMessage of form.controls.rrp['errorMessages']">
      {{errorMessage}}
    </mat-error>
  </mat-form-field>

  <div fxLayout="row" fxLayoutAlign="center center">
    <b fxLayoutAlign="start center">Final Sale Price {{finalSalePriceVATLabel}}</b>
    <b fxFlex fxLayoutAlign="end center">{{finalSalePrice}}</b>
  </div>
  <br>


</div>


<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-button color="warn" (click)="close()">Cancel</button>
  <button mat-raised-button color="primary" (click)="save()">Save</button>
</mat-dialog-actions>