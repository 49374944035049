<mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="center center">
    <div fxFlex="80" fxLayout="row" fxLayoutAlign="start center">
        <button *ngIf="drawer" type="button" aria-label="Toggle sidenav" mat-icon-button (click)="toggleDrawer()">
            <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        <!-- <a mat-button routerLink="/terminal" routerLinkActive="router-link-active">
            <mat-icon>point_of_sale</mat-icon>
            Sales Terminal
        </a>
        <a mat-button routerLink="/sales" routerLinkActive="router-link-active">
            <mat-icon>show_chart</mat-icon>
            Invoice History
        </a> -->
        <div>
            <h2>Admin Panel</h2>
        </div>
    </div>
    <div fxFlex="20" fxLayoutAlign="end center">

        <!-- <app-user-menu></app-user-menu> -->
    </div>
</mat-toolbar>