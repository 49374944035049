import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PurchasedItem, Tax } from 'src/app/models/inventory';
import { SalesTransaction } from 'src/app/models/salesTransaction';
import * as taxHelpers from '../../taxHelpers';


@Component({
  selector: 'app-print-receipt',
  templateUrl: './print-receipt.component.html',
  styleUrls: ['./print-receipt.component.scss']
})
export class PrintReceiptComponent implements OnInit {

  @Input() salesTransaction: SalesTransaction//{ purchasedItems: PurchasedItem[], taxList: { id: number, name: string, amount: number }[] };
  @Input() autoPrint: boolean;
  returnRoute: string;
  showCashChange: boolean;
  showBuyerInfo: boolean;
  copyInvoice: boolean = false;
  printStyle: string;

  // need to use detectChanges because we're modifying purchaseList in afterViewChecked()
  constructor(private cdr: ChangeDetectorRef, private router: Router) {
    this.returnRoute = this.router.getCurrentNavigation().extras?.state?.returnRoute;
    this.copyInvoice = this.router.getCurrentNavigation().extras?.state?.copyInvoice;
  }


  ngOnInit(): void {
    // this.showCashChange = true;
    this.printStyle = localStorage.getItem('printStyle');
  }

  ngAfterViewChecked() {
    if (this.salesTransaction) {
      if (this.salesTransaction.isRefund) {
        this.showCashChange = false;
        console.log('refund reason:', this.salesTransaction);
      }
      else { this.showCashChange = true; }
      console.log(`cash:${this.salesTransaction.tendered}, change:${this.salesTransaction.changeDue}, cashier:${this.salesTransaction.cashier},
      saleType:${this.salesTransaction.transactionType}`);
      if (this.salesTransaction.customer) {
        console.log('show buyer info');
        this.showBuyerInfo = true;
      }
      else {
        console.log('DO NOT show buyer info');
        this.showBuyerInfo = false;
      }

      console.log('taxList:', this.salesTransaction.taxList);
      console.log('taxItems:', this.salesTransaction.response.TaxItems);

      window.print();
      this.salesTransaction = null;

      this.cdr.detectChanges();
      if (this.returnRoute) this.router.navigate([this.returnRoute]);
    }
  }

  getTaxName(id: Tax[]): string {
    var taxes = '';
    id.forEach(x => {
      const taxName = this.salesTransaction.taxList.find(i => i.code === x.code);
      taxName ? taxes += taxName.code + ' ' : '';
    })

    return taxes;
  }

  getTaxInclusivePrice(item: PurchasedItem): number {
    const totalPrice = taxHelpers.calculateTaxInclusivePrice(item.product.price, item.product.taxCodes, item.product.rrp);
    console.log(`item name: ${item.product.name}, item price: ${item.product.price}, totalPrice: ${totalPrice}`);
    return item.quantity * totalPrice;
  }

  saleType(t: number) {
    if (t === 1) return 'Credit Sale';
    else return 'Cash Sale';
  }


}
