<div fxLayout="row" fxLayoutAlign="stretch center" fxLayoutGap>
  <div fxLayoutAlign="start center" class="add-button">
    <button mat-raised-button color="accent" (click)="addItem()">
      <mat-icon>add</mat-icon>
      Add Tax
    </button>
  </div>
  <div class="add-button" fxFlex fxLayoutAlign="end center">
    <button mat-button warn color="secondary" (click)="importESDTaxes()">
      <mat-icon>import_export</mat-icon>
      Import from ESD
    </button>
  </div>

</div>

<div class="mt-2 mat-elevation-z8">
  <mat-table [dataSource]="dataSource" matSort>



    <ng-container matColumnDef="name">
      <mat-header-cell fxFlex="25" *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
      <mat-cell fxFlex="25" *matCellDef="let row" data-label="name"> {{row.name}} </mat-cell>
    </ng-container>

    <!-- <ng-container matColumnDef="description">
      <mat-header-cell fxFlex="20" *matHeaderCellDef mat-sort-header> Description </mat-header-cell>
      <mat-cell fxFlex="20" *matCellDef="let row" data-label="description"> {{row.description}} </mat-cell>
    </ng-container> -->

    <ng-container matColumnDef="amount">
      <mat-header-cell fxFlex="10" *matHeaderCellDef mat-sort-header> Amount </mat-header-cell>
      <mat-cell fxFlex="10" *matCellDef="let row" [style.color]="row.color" data-label="amount"> {{row.amount}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="code">
      <mat-header-cell fxFlex="10" *matHeaderCellDef mat-sort-header> Tax label </mat-header-cell>
      <mat-cell fxFlex="10" *matCellDef="let row" [style.color]="row.color" data-label="code"> {{row.code}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="effectiveDate">
      <mat-header-cell fxFlex="15" *matHeaderCellDef mat-sort-header> Effective Date </mat-header-cell>
      <mat-cell fxFlex="15" *matCellDef="let row" [style.color]="row.color" data-label="effectiveDate">
        {{row.effectiveDate | date}}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="expiredDate">
      <mat-header-cell fxFlex="15" *matHeaderCellDef mat-sort-header> Expired Date </mat-header-cell>
      <mat-cell fxFlex="15" *matCellDef="let row" [style.color]="row.color" data-label="expiredDate">
        {{row.expiredDate | date}}
      </mat-cell>
    </ng-container>




    <!-- actions -->
    <ng-container matColumnDef="actions">
      <mat-header-cell fxLayoutAlign="end center" *matHeaderCellDef>

      </mat-header-cell>
      <mat-cell fxLayoutAlign="end center" *matCellDef="let row; let i=index;">
        <button mat-icon-button color="primary" (click)="editItem(row)">
          <mat-icon aria-label="Edit">edit</mat-icon>
        </button>

        <button mat-icon-button color="basic" (click)="deleteItem(row)">
          <mat-icon aria-label="Delete">delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;">
    </mat-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
</div>