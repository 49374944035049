import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ESD, ESDConfig } from '../models/esd';
import { ESDSignedResponse, ESDSignInvoiceRequest } from '../models/esdSigning';
import { WINDOW } from '../window';


@Injectable({
  providedIn: 'root'
})
export class EsdService {
  private apiUrl: string;
  private tempResponse: string;
  constructor(
    @Inject(WINDOW) private window: Window,
    protected httpClient: HttpClient,
    private datePipe: DatePipe

  ) {
    this.apiUrl = `${environment.protocol}${this.window.location.hostname}:${environment.port}${environment.apiUrl}`;
  }

  getStatus(): Observable<ESD> {
    return this.httpClient.get<ESD>(`${this.apiUrl}/esd`).pipe(
      tap(result => {
        localStorage.setItem('esd', JSON.stringify(result));
      })
    );
  }

  getESDStatus(fromDevice: boolean): Observable<ESD> {
    const e = JSON.parse(localStorage.getItem('esd')) as ESD;
    if (e && !fromDevice) {
      return of(e);
    }
    else {
      return this.getStatus();
    }
  }

  isESDSetup(): boolean {
    const status = this.getESDStatus(false);
    return status ? true : false;
  }

  signInvoice(request: ESDSignInvoiceRequest): Observable<ESDSignedResponse> {
    return this.httpClient.post<ESDSignedResponse>(`${this.apiUrl}/esd`, request);
    //return of(this.getTempResponse());
  }

  getESDConfig(): Observable<ESDConfig> {
    return this.httpClient.get<ESDConfig>(`${this.apiUrl}/esdConfig`);
  }

  updateESDConfig(config: ESDConfig) {
    return this.httpClient.post<ESDConfig>(`${this.apiUrl}/esdConfig`, config);
  }

  getEsdDateTime(esd: string): string {
    let esdTime = '';
    esdTime += esd.substr(0, 4) + '-';
    esdTime += esd.substr(4, 2) + '-';
    esdTime += esd.substr(6, 2) + ', ';
    esdTime += esd.substr(8, 2) + ':';
    esdTime += esd.substr(10, 2) + ':';
    esdTime += esd.substr(12, 2);
    return esdTime;
  }

  getTempResponse() {
    return {
      TPIN: "1001711461",
      TaxpayerName: "PREMUNI INVESTMENTS LIMITED",
      BuyerTPIN: "123123123",
      BuyerName: "Bob Smith",
      Addreess: "2313",
      ESDTime: "20201014054112",
      TerminalID: "010300000035",
      InvoiceCode: "000200110000",
      InvoiceNumber: "00046512",
      FiscalCode: "54055426355042640403",
      TalkTime: "",
      Operator: "",
      TaxItems: [
        {
          TaxLabel: "A",
          CategoryName: "STANDARD RATED",
          Rate: 0.16,
          TaxAmount: 1.92
        },
        {
          TaxLabel: "B",
          CategoryName: "MTVs",
          Rate: 0.16,
          TaxAmount: 1.39
        }
      ],
      TotalAmount: 24,
      VerificationUrl: "http://efd.zra.org.zm:8097/search/invoiceInspection/invoiceVerification?x=%7B%22INV%22%3A%7B%22IC%22%3A%22000200110000%22%2C%22IN%22%3A%2200046512%22%2C%22TPIN%22%3A%221001711461%22%2C%22MACH%22%3A%22010300000035%22%2C%22DATE%22%3A%2220201014054112%22%2C%22AMT%22%3A%220000000024.00%22%2C%22FSC%22%3A%2254055426355042640403%22%7D%7D",
      VerificationQRCode: ""
    }
  }





}
