import { BrowserModule, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators'; // <-- #2 import module


import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpCredentialsInterceptor } from './interceptors/CredentialsInterceptor';
import { ErrorInterceptor } from './interceptors/ErrorInterceptor';
import { InventoryService } from './services/inventory.service';
import { UserService } from './services/user.service';
import { EsdService } from './services/esd.service';
import { SalesService } from './services/sales.service';
import { WINDOW_PROVIDERS } from './window';

import { SharedMaterialModule } from './modules/shared-material.module';
import { SharedComponentsModule } from './modules/shared-components.module';
import { SigninComponent } from './components/signin/signin.component';
import { CategoriesComponent } from './components/admin/categories/categories.component';
import { ProductsComponent } from './components/admin/products/products.component';
import { TaxesComponent } from './components/admin/taxes/taxes.component';
import { EditCategoryDialogComponent } from './components/admin/dialogs/edit-category-dialog/edit-category-dialog.component';
import { EditProductDialogComponent } from './components/admin/dialogs/edit-product-dialog/edit-product-dialog.component';
import { EditTaxesDialogComponent } from './components/admin/dialogs/edit-taxes-dialog/edit-taxes-dialog.component';
import { EditUserDialogComponent } from './components/admin/dialogs/edit-user-dialog/edit-user-dialog.component';
import { ConfirmationDialogComponent } from './components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { AuthService } from './services/auth.service';
import { AdminPanelComponent } from './components/admin/admin-panel/admin-panel.component';
import { LayoutModule } from '@angular/cdk/layout';
import { SalesComponent } from './components/sales/sales.component';
import { SalesTerminalComponent } from './components/sales-terminal/sales-terminal.component';
import { EditPurchasedItemComponent } from './components/dialogs/edit-purchased-item/edit-purchased-item.component';
import { UserMenuComponent } from './components/shared/user-menu/user-menu.component';
import { EsdComponent } from './components/admin/esd/esd.component';
import { PrintReceiptComponent } from './components/print-receipt/print-receipt.component';
import { TerminalComponent } from './components/terminal/terminal.component';
import { UsersComponent } from './components/admin/users/users.component';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { QRCodeSVGModule } from 'ngx-qrcode-svg';
import { CompleteSaleComponent } from './components/sales-terminal/complete-sale/complete-sale.component';
import { TransactionResultComponent } from './components/sales-terminal/transaction-result/transaction-result.component';
import { AuthGuard } from './auth/auth.guard';
import { AdminGuard } from './auth/admin.guard';
import { SupervisorGuard } from './auth/supervisor.guard';
import { IsLoadingService } from '@service-work/is-loading';
import { CustomersComponent } from './components/admin/customers/customers.component';
import { EditCustomerDialogComponent } from './components/admin/dialogs/edit-customer-dialog/edit-customer-dialog.component';
import { SelectCustomerComponent } from './components/shared/select-customer/select-customer.component';
import { CustomPrintComponent } from './components/custom-print/custom-print.component';
import { SageCurrencyPipe } from './pipes/sage-currency.pipe';
import { RefundComponent } from './components/refund/refund.component';
import { HeaderToolbarComponent } from './components/header-toolbar/header-toolbar.component';
import { DashboardComponent } from './components/admin/dashboard/dashboard.component';
import { MyHammerConfig } from './my-hammer.config';
import { PrintComponent } from './components/admin/print/print.component';



@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    CategoriesComponent,
    ProductsComponent,
    TaxesComponent,
    EditCategoryDialogComponent,
    EditProductDialogComponent,
    EditTaxesDialogComponent,
    EditUserDialogComponent,
    ConfirmationDialogComponent,
    AdminPanelComponent,
    SalesComponent,
    SalesTerminalComponent,
    EditPurchasedItemComponent,
    UserMenuComponent,
    EsdComponent,
    PrintReceiptComponent,
    TerminalComponent,
    UsersComponent,
    CompleteSaleComponent,
    TransactionResultComponent,
    CustomersComponent,
    EditCustomerDialogComponent,
    SelectCustomerComponent,
    CustomPrintComponent,
    SageCurrencyPipe,
    RefundComponent,
    HeaderToolbarComponent,
    DashboardComponent,
    PrintComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HammerModule,
    FormsModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    HttpClientModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    SharedMaterialModule,
    SharedComponentsModule,
    LayoutModule,
    QRCodeSVGModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'en-US' },
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
    SageCurrencyPipe,
    HttpClient,
    AuthService,
    InventoryService,
    UserService,
    EsdService,
    SalesService,
    IsLoadingService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpCredentialsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    DatePipe,
    AuthGuard,
    AdminGuard,
    SupervisorGuard,
    WINDOW_PROVIDERS
  ],

  bootstrap: [AppComponent],
  entryComponents: [EditCategoryDialogComponent, ConfirmationDialogComponent]
})
export class AppModule { }
