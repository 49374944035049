import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Category, Product, PurchasedItem, Tax } from '../models/inventory';
import { PagedData } from "../models/pagedData";
import { Observable } from 'rxjs';
import { WINDOW } from '../window';


@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  private apiUrl: string;

  constructor(
    @Inject(WINDOW) private window: Window,
    protected httpClient: HttpClient) {
    this.apiUrl = `${environment.protocol}${this.window.location.hostname}:${environment.port}${environment.apiUrl}`;
  }

  // Categories

  getCategories(): Observable<Category[]> {
    console.log(`catgories: apiurl is ${this.apiUrl}`);
    return this.httpClient.get<Category[]>(`${this.apiUrl}/categories`);
  }

  getCategoriesById(id: number): Observable<Category> {
    return this.httpClient.get<Category>(`${this.apiUrl}/categories/${id}`);
  }

  createCategory(category: Category) {
    return this.httpClient.post<Category>(`${this.apiUrl}/categories`, category);
  }

  updateCategory(id: number, category: Category) {
    return this.httpClient.put<Category>(`${this.apiUrl}/categories/${id}`, category);
  }



  deleteCategory(id: number) {
    return this.httpClient.delete<Category>(`${this.apiUrl}/categories/${id}`);
  }

  // Products

  getProducts(productCode?: string): Observable<Product[]> {
    var url = `${this.apiUrl}/products`;
    if (productCode) url += `?productCode=${productCode}`;
    return this.httpClient.get<Product[]>(url);
  }

  getProductsPaged(page: number, size: number, sortName: string, sortDirection: string, filteredText?: string, categoryName?: string): Observable<PagedData<Product[]>> {
    if (sortDirection == '') {
      sortName = 'name';
      sortDirection = 'asc';
    }
    let url = `${this.apiUrl}/products/pagination?page=${page}&size=${size}&sortName=${sortName}&sortDirection=${sortDirection}`;
    if (filteredText) {
      url += `&filterText=${filteredText}`;
    }
    if (categoryName) {
      url += `&category=${categoryName}`;
    }
    return this.httpClient.get<PagedData<Product[]>>(url);
  }

  getProductsById(id: number): Observable<Product> {
    return this.httpClient.get<Product>(`${this.apiUrl}/products/${id}`);
  }

  createProduct(products: Product) {
    return this.httpClient.post<Product>(`${this.apiUrl}/products`, products);
  }

  updateProduct(id: number, products: Product) {
    return this.httpClient.put<Product>(`${this.apiUrl}/products/${id}`, products);
  }

  deleteProduct(id: number) {
    return this.httpClient.delete<Product>(`${this.apiUrl}/products/${id}`);
  }

  updateQuantities(productList: PurchasedItem[]) {
    var products: { id: number, quantity: number }[] = [];
    productList.forEach(element => {
      products.push({ id: element.product.id, quantity: element.quantity });
    });
    console.log('updating prodcuts:', products);
    return this.httpClient.post(`${this.apiUrl}/products/updateQuantities`, products);
  }

  // Taxes

  getTaxes(): Observable<Tax[]> {
    return this.httpClient.get<Tax[]>(`${this.apiUrl}/taxes`);
  }

  getTaxById(id: number): Observable<Tax> {
    return this.httpClient.get<Tax>(`${this.apiUrl}/taxes/${id}`);
  }

  createTax(tax: Tax) {
    return this.httpClient.post<Tax>(`${this.apiUrl}/taxes`, tax);
  }

  updateTax(id: number, tax: Tax) {
    return this.httpClient.put<Tax>(`${this.apiUrl}/taxes/${id}`, tax);
  }

  deleteTax(id: number) {
    return this.httpClient.delete<Tax>(`${this.apiUrl}/taxes/${id}`);
  }

  // Helper methods
  private formatCategory(cat: Category): Category {
    var codes = [];
    cat.taxCodes.forEach(c => {
      codes.push(c.id);
    });
    cat.taxCodes = codes;
    return cat;
  }

}
