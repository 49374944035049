<div>
    <div fxLayout="row" fxLayoutAlign="start start">
        <button mat-raised-button color="accent" (click)="addItem()" fxLayoutAlign="start end">
            <mat-icon>add</mat-icon>
            Add Customer
        </button>
    </div>
    <div fxFlex="80" fxLayoutAlign="start center" fxFlexOffset="2">
        <mat-icon>search</mat-icon>
        <mat-form-field fxFlex>
            <mat-label>Search for customers</mat-label>
            <input matInput #input>
        </mat-form-field>
    </div>
</div>

<div class="mt-2 mat-elevation-z8">

    <mat-table [dataSource]="customers" matSort matSortActive="firstName" matSortDirection="asc">

        <ng-container matColumnDef="firstName">
            <mat-header-cell fxFlex="15" *matHeaderCellDef mat-sort-header> First Name </mat-header-cell>
            <mat-cell fxFlex="15" *matCellDef="let row" data-label="firstName">
                <div fxLayout="column">
                    <div>
                        {{row.firstName}}
                    </div>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="lastName">
            <mat-header-cell fxFlex="15" *matHeaderCellDef mat-sort-header> Last Name </mat-header-cell>
            <mat-cell fxFlex="15" *matCellDef="let row" data-label="lastName">
                <div fxLayout="column">
                    <div>
                        {{row.lastName}}
                    </div>
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="companyName">
            <mat-header-cell fxFlex="15" *matHeaderCellDef mat-sort-header> Company </mat-header-cell>
            <mat-cell fxFlex="15" *matCellDef="let row" data-label="companyName">
                <div fxLayout="column">
                    <div>
                        {{row.companyName}}
                    </div>
                </div>
            </mat-cell>

        </ng-container>

        <ng-container matColumnDef="address">
            <mat-header-cell fxFlex="20" *matHeaderCellDef mat-sort-header> Address </mat-header-cell>
            <mat-cell class="paddedCell" fxFlex="20" *matCellDef="let row" data-label="address"> {{row.address}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="email">
            <mat-header-cell fxFlex="15" *matHeaderCellDef mat-sort-header> Email </mat-header-cell>
            <mat-cell class="paddedCell" fxFlex="15" *matCellDef="let row" data-label="email"> {{row.email}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="telephone">
            <mat-header-cell fxFlex="10" *matHeaderCellDef mat-sort-header> Tel </mat-header-cell>
            <mat-cell fxFlex="10" *matCellDef="let row" [style.color]="row.color" data-label="telephone">
                {{row.telephone}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="TPIN">
            <mat-header-cell fxFlex="10" *matHeaderCellDef mat-sort-header> TPIN </mat-header-cell>
            <mat-cell fxFlex="10" fxLayoutAlign="start center" *matCellDef="let row" [style.color]="row.color"
                data-label="TPIN"> {{row.TPIN}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="taxAccountName">
            <mat-header-cell fxFlex="20" *matHeaderCellDef mat-sort-header> Tax Acct Name </mat-header-cell>
            <mat-cell fxFlex="20" *matCellDef="let row" [style.color]="row.color" data-label="taxAccountName">
                {{row.taxAccountName}}</mat-cell>
        </ng-container>



        <!-- actions -->
        <ng-container matColumnDef="actions">
            <mat-header-cell fxFlex="14" fxLayoutAlign="end center" *matHeaderCellDef>

            </mat-header-cell>
            <mat-cell fxFlex="14" fxLayoutAlign="end center" *matCellDef="let row; let i=index;">
                <button mat-icon-button color="primary" (click)="editItem(row)">
                    <mat-icon aria-label="Edit">edit</mat-icon>
                </button>

                <button mat-icon-button color="basic" (click)="deleteItem(row)">
                    <mat-icon aria-label="Delete">delete</mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;">
        </mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[10, 25, 100]" [length]="resultsLength" showFirstLastButtons></mat-paginator>
</div>