import { Component, OnInit } from '@angular/core';
import { matxAnimations } from "../../shared/animations/matx-animations";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ReactiveFormConfig, RxFormBuilder, RxwebValidators } from '@rxweb/reactive-form-validators';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { User } from '../../models/user';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
  animations: matxAnimations
})
export class SigninComponent implements OnInit {
  signinForm: FormGroup;
  errorMsg = '';



  constructor(
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private fb: RxFormBuilder) { }

  ngOnInit(): void {
    ReactiveFormConfig.set({
      "validationMessage": {
        "required": "This field is required"
      }
    });
    this.signinForm = this.fb.group({
      username: ['', RxwebValidators.required()],
      password: ['', RxwebValidators.required()]
    });
    console.log(`logged in: ${this.authService.isLoggedIn()}`);
    if (this.authService.isLoggedIn()) this.router.navigateByUrl('/terminal');
  }

  signIn() {
    this.errorMsg = '';
    if (!this.signinForm.valid) return;
    const signInData = this.signinForm.value;

    this.authService.signIn(signInData.username, signInData.password).subscribe(result => {
      this.userService.saveUser(result as User);
      this.router.navigateByUrl('/terminal');

    },
      err => {
        console.log('error signing in:', err);
        this.errorMsg = 'Incorrect Credentials';
      }

    );

  }



}
