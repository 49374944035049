<h2 mat-dialog-title>{{title}}</h2>

<mat-dialog-content fxLayout="column" [formGroup]="form">
    <mat-form-field>
        <input matInput placeholder="Name" formControlName="name" readonly>
    </mat-form-field>
    <mat-form-field>
        <input matInput placeholder="Description" formControlName="description" readonly>
    </mat-form-field>
    <mat-form-field>
        <input matInput placeholder="price" formControlName="price" readonly>
        <mat-error *ngFor="let errorMessage of form.controls.price['errorMessages']">
            {{errorMessage}}
        </mat-error>
    </mat-form-field>
    <mat-form-field>
        <input matInput type="number" placeholder="quantity" formControlName="quantity" required>
        <mat-error *ngFor="let errorMessage of form.controls.quantity['errorMessages']">
            {{errorMessage}}
        </mat-error>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="center center">
    <div fxLayoutAlign="start center" fxFlex="30">
        <button mat-raised-button color="warn" (click)="removeItem()">Remove Item</button>
    </div>
    <div fxLayoutAlign="end center" fxFlex="70">
        <button mat-button color="warn" (click)="close()">Cancel</button>
        <button mat-raised-button color="primary" (click)="save()">Save</button>
    </div>

</mat-dialog-actions>