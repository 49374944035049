import { DatePipe } from '@angular/common';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatListOption } from '@angular/material/list';
import { Customer } from 'src/app/models/customer';
import { ESD } from 'src/app/models/esd';
import { ESDSignedResponse, ESDSignInvoiceRequest, Item } from 'src/app/models/esdSigning';
import { PurchasedItem } from 'src/app/models/inventory';
import { Sales } from 'src/app/models/sales';
import { SalesTransaction } from 'src/app/models/salesTransaction';
import { EsdService } from 'src/app/services/esd.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { UserService } from 'src/app/services/user.service';
import { TransactionResultComponent } from '../sales-terminal/transaction-result/transaction-result.component';
import { forkJoin, Observable } from 'rxjs';
import { SalesService } from 'src/app/services/sales.service';
import { Router } from '@angular/router';
import * as taxHelpers from '../../taxHelpers';

@Component({
  selector: 'app-refund',
  templateUrl: './refund.component.html',
  styleUrls: ['./refund.component.scss']
})
export class RefundComponent implements OnInit {
  @ViewChild('purchasedItems') private purchaseList;
  @ViewChild('input') input: ElementRef;
  private dialogConfig: MatDialogConfig;
  esdStatus: ESD;
  title: string;
  invoiceDetails: Sales;
  transactionDetails: SalesTransaction;
  returnReason: string = '';
  customer: Customer;
  selectToggle = false;
  selectAllText = '';
  totalRefundAmount = 0;
  refundDisabled = false;

  selectedItems: PurchasedItem[] = [];
  refundItems: PurchasedItem[] = [];

  constructor(
    private dialogRef: MatDialogRef<RefundComponent>,
    private inventoryService: InventoryService,
    private esdService: EsdService,
    private datePipe: DatePipe,
    private userService: UserService,
    private salesService: SalesService,
    private router: Router,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    if (data) {
      this.invoiceDetails = data.saleDetails;
      this.transactionDetails = JSON.parse(this.invoiceDetails.transactionDetails);
      if (this.transactionDetails.customer) this.customer = this.transactionDetails.customer;
      this.transactionDetails.purchasedItems.forEach(x => {
        this.refundItems.push(Object.assign({}, x));
      })
    }
  }

  ngOnInit(): void {
    this.dialogConfig = new MatDialogConfig();
    this.dialogConfig.width = '30rem';
    this.dialogConfig.maxHeight = '85vh';
    this.esdService.getESDStatus(false).subscribe((esd) => { this.esdStatus = esd; })
    this.title = "Credit Note";
    !this.selectToggle ? this.selectAllText = 'Select All' : this.selectAllText = 'De-select All';
  }

  validateQuantity(event, item) {
    event.stopPropagation();
    const newValue = event.target.value;
    if (newValue < 0 || newValue > item.quantity) event.target.value = item.quantity;
    let q = this.refundItems.find(x => x.product.id === item.product.id);
    q.quantity = parseInt(event.target.value);
    this.calculateTotalRefund();
  }

  inputClicked(event) {
    event.stopPropagation();
  }

  selectAllToggle() {
    this.selectToggle ? this.purchaseList.deselectAll() : this.purchaseList.selectAll();
    this.selectToggle = !this.selectToggle;
    !this.selectToggle ? this.selectAllText = 'Select All' : this.selectAllText = 'De-select All';
  }

  calcItemRefund(item: PurchasedItem): number {
    const totalPrice = taxHelpers.calculateTaxInclusivePrice(item.product.price, item.product.taxCodes, item.product.rrp);
    const q = this.refundItems.find(x => x.product.id === item.product.id);
    return q.quantity * totalPrice;
  }

  calculateTotalRefund() {
    this.totalRefundAmount = 0;
    this.selectedItems.forEach(x => {
      this.totalRefundAmount += this.calcItemRefund(x);
    })
  }

  onNgModelChange(event) {
    if (this.selectedItems) {
      this.selectedItems.forEach(x => {
      });
    }
    this.calculateTotalRefund();

  }


  refund() {
    if (this.input.nativeElement.value === '') return;
    this.refundDisabled = true;
    const esdRequest = this.getESDRequest();
    console.log('esdRequest:', JSON.stringify(esdRequest));

    this.esdService.signInvoice(esdRequest).subscribe(result => {
      this.close();
      this.dialogConfig.data = { changeDue: this.totalRefundAmount, refund: true, success: true };
      const transactionResult = this.dialog.open(TransactionResultComponent, this.dialogConfig);
      transactionResult.afterClosed().subscribe(() => {
        // record sale and print

        result.ESDTime = this.esdService.getEsdDateTime(result.ESDTime); //format the date/time
        this.recordSaleRefund(result);
      });


      // print receipt here

    },
      error => {
        this.dialogConfig.data = { changeDue: this.totalRefundAmount, refund: true, success: false, errorCode: error.error.ErrorCode, errorDescription: error.error.Description };
        const transactionResult = this.dialog.open(TransactionResultComponent, this.dialogConfig);
        this.close();

      });

  }

  close() {
    this.dialogRef.close();
  }

  private getESDRequest(): ESDSignInvoiceRequest {
    if (!this.esdStatus) return null;
    let items: Item[] = [];

    for (let i = 0; i < this.selectedItems.length; i++) {
      let taxLabels: string[] = [];
      //let rrp = 0;
      this.selectedItems[i].product.taxCodes.forEach(taxCode => {
        const t = this.selectedItems[i].product.taxCodes.find(x => x.id === taxCode.id);
        // if (t.code === "B") rrp = this.selectedItems[i].product.price;
        taxLabels.push(t.code);
      });

      const q = this.refundItems.find(x => x.product.id === this.selectedItems[i].product.id);


      const item: Item = {
        ItemId: i + 1,
        Description: this.selectedItems[i].product.name,
        BarCode: this.selectedItems[i].product.productCode,
        Quantity: q.quantity * -1,
        UnitPrice: (this.selectedItems[i].product.price), //* -1,
        Discount: 0,
        TaxLabels: taxLabels,
        TotalAmount: (this.selectedItems[i].product.price * (q.quantity * -1)),// * -1,
        isTaxInclusive: false,
        RRP: this.selectedItems[i].product.rrp
      }

      items.push(item);

    }



    let esdRequest: ESDSignInvoiceRequest = {
      PosSerialNumber: this.esdStatus.SerialNumber,
      PosVendor: this.esdStatus.Manufacture,
      PosModel: this.esdStatus.Model,
      PosSoftVersion: this.esdStatus.SoftwareVersion,
      IssueTime: this.datePipe.transform(Date.now(), 'yyyyMMddHHmmss'),
      TransactionType: 1,
      PaymentMode: 0,
      SaleType: 0,
      Cashier: this.userService.user.firstName,
      OriginalInvoiceCode: this.transactionDetails.response.InvoiceCode,
      OriginalInvoiceNumber: this.transactionDetails.response.InvoiceNumber,
      Memo: this.returnReason,
      Items: items
    }
    if (this.customer) {
      esdRequest.BuyerTPIN = this.customer.TPIN;
      esdRequest.BuyerName = `${this.customer.firstName} ${this.customer.lastName}`;
      esdRequest.BuyerTaxAccountName = this.customer.taxAccountName;
      esdRequest.BuyerTel = this.customer.telephone;
      esdRequest.LocalPurchaseOrder = this.customer.lpo ? this.customer.lpo.toString() : null;
    }
    return esdRequest;
  }

  recordSaleRefund(esdResponse: ESDSignedResponse) {
    let totalVAT = 0;
    let taxTotal = 0;
    esdResponse.TaxItems.forEach(x => {
      totalVAT += x.TaxAmount;
    });

    this.selectedItems.forEach(i => {
      const q = this.refundItems.find(x => x.product.id === x.product.id);
      i.quantity = q.quantity * -1;
    });
    console.log(`selected Items: ${this.selectedItems}`);

    const salesTransaction: SalesTransaction = {
      purchasedItems: this.selectedItems,
      taxList: this.transactionDetails.taxList,
      esd: this.esdStatus,
      response: esdResponse,
      saleType: this.transactionDetails.saleType,
      totalVAT: totalVAT,
      total: esdResponse.TotalAmount,
      customer: this.customer,
      tendered: 0,
      changeDue: this.totalRefundAmount,
      cashier: this.userService.user.firstName,
      isRefund: true,
      transactionType: 1,
      refundReason: this.returnReason,
      OriginalInvoiceCode: this.transactionDetails.response.InvoiceCode,
      OriginalInvoiceNumber: this.transactionDetails.response.InvoiceNumber
    }

    let paymentType = 0;
    if (this.transactionDetails.paymentType && this.transactionDetails.paymentType.id) paymentType = this.transactionDetails.paymentType.id;

    const sale: Sales = {
      invoiceNumber: esdResponse.InvoiceNumber,
      salesTotal: esdResponse.TotalAmount,
      productTotal: (esdResponse.TotalAmount - totalVAT),
      taxTotal: totalVAT,
      saleType: this.transactionDetails.saleType,
      customer: this.transactionDetails.customer,
      receiptUrl: esdResponse.VerificationUrl,
      tendered: 0,
      transactionType: 1,
      paymentType: paymentType,
      changeDue: esdResponse.TotalAmount,
      refundReason: this.returnReason,
      transactionDetails: JSON.stringify(salesTransaction)
    }

    // update product quantities and record sale
    forkJoin([this.inventoryService.updateQuantities(this.selectedItems),
    this.salesService.createSale(sale)]).subscribe(([quantities, sales]) => {
    });
    this.router.navigate(['print'], { state: { transaction: salesTransaction, returnRoute: this.router.url, isRefund: true } });
  }


}
