import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  title: string;
  description: string;
  okLabel: string;
  cancelLabel: string;

  constructor(
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) { 
    this.title = data.title;
    this.description = data.description;
    this.okLabel = data.okLabel;
    this.cancelLabel = data.cancelLabel;
  }

  ngOnInit(): void {
  
  }

  cancel(){
    this.dialogRef.close();
  }

  ok(){
    this.dialogRef.close(true);
  }

}
