<!-- <app-header-toolbar></app-header-toolbar>
 -->
<!-- Search Bar -->
<div class="row m-0">
    <div class="col m-0 p-0">
        <div class="search-items d-flex align-items-center shadow">

            <app-user-menu class="p-md-2">
            </app-user-menu>
            <mat-divider [vertical]="true" style="height:30px"></mat-divider>
            <div class="d-flex justify-content-center align-items align-items-center w-75">
                <mat-icon fxLayoutAlign>search</mat-icon>
                <mat-form-field class="search-field w-50">
                    <mat-label>Search Invoice #</mat-label>
                    <input matInput [value]="filterValue" #input>
                </mat-form-field>

            </div>



        </div>

    </div>
</div>
<!-- Loading Bar -->
<div class="loading">
    <mat-progress-bar *ngIf="isLoading | async" mode="indeterminate" color="warn"></mat-progress-bar>
</div>
<div class="container mat-elevation-z8">
    <mat-table [dataSource]="sales" matSort matSort matSortActive="createdAt" matSortDirection="desc">

        <ng-container matColumnDef="createdAt">
            <mat-header-cell fxFlex="25" *matHeaderCellDef mat-sort-header> Date </mat-header-cell>
            <mat-cell fxFlex="25" *matCellDef="let row" [style.color]="row.color" data-label="createdAt">
                <div>{{row.createdAt | date: 'mediumDate':'+0200'}} <small>({{row.createdAt | date:
                        'shortTime':'+0200'}})</small></div>
                <!-- <div fxLayout="column">
                    <div>{{row.createdAt | date: 'longDate':'+0200'}}</div>
                    <div><small>{{row.createdAt | date: 'shortTime':'+0200'}}</small>
                    </div>
                </div> -->

            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="invoiceNumber">
            <mat-header-cell fxFlex="15" *matHeaderCellDef mat-sort-header> Invoice # </mat-header-cell>
            <mat-cell fxFlex="15" *matCellDef="let row" data-label="invoiceNumber">
                <b>{{row.invoiceNumber}}</b>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="salesTotal">
            <mat-header-cell fxFlex="15" *matHeaderCellDef mat-sort-header> Sale Amount </mat-header-cell>
            <mat-cell fxFlex="15" *matCellDef="let row" [style.color]="row.color" data-label="salesTotal">
                {{row.salesTotal | currency}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="productTotal">
            <mat-header-cell fxFlex="15" *matHeaderCellDef mat-sort-header> Product Total </mat-header-cell>
            <mat-cell fxFlex="15" *matCellDef="let row" [style.color]="row.color" data-label="productTotal">
                {{row.productTotal | sageCurrency}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="taxTotal">
            <mat-header-cell fxFlex="15" *matHeaderCellDef mat-sort-header> Tax Total </mat-header-cell>
            <mat-cell fxFlex="15" *matCellDef="let row" [style.color]="row.color" data-label="taxTotal">
                {{row.taxTotal | sageCurrency}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="tendered">
            <mat-header-cell fxFlex="10" *matHeaderCellDef mat-sort-header> Tendered </mat-header-cell>
            <mat-cell fxFlex="10" *matCellDef="let row" [style.color]="row.color" data-label="tendered">
                {{row.tendered | sageCurrency}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="changeDue">
            <mat-header-cell fxFlex="10" *matHeaderCellDef mat-sort-header> Change </mat-header-cell>
            <mat-cell fxFlex="10" *matCellDef="let row" [style.color]="row.color" data-label="changeDue">
                {{row.changeDue | sageCurrency}}
            </mat-cell>
        </ng-container>



        <ng-container matColumnDef="refund">
            <mat-header-cell fxFlex="10" *matHeaderCellDef></mat-header-cell>
            <mat-cell fxFlex="10" *matCellDef="let row" [style.color]="row.color" data-label="refund">
                <div *ngIf="row.transactionType ===1">
                    <mat-chip-list>
                        <mat-chip color="warn" selected><small>Credit</small></mat-chip>
                    </mat-chip-list>
                </div>
                <div *ngIf="row.transactionType ===2">
                    <mat-chip-list>
                        <mat-chip color="accent" selected><small>Debit</small></mat-chip>
                    </mat-chip-list>
                </div>
                <div *ngIf="row.saleType ===1">
                    <mat-chip-list>
                        <mat-chip color="accent" selected><small>Unpaid</small></mat-chip>
                    </mat-chip-list>
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
            <mat-header-cell fxFlex="5" *matHeaderCellDef>
                More
            </mat-header-cell>
            <mat-cell fxFlex="5" *matCellDef="let row" [style.color]="row.color" data-label="actions">
                <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="showReceipt(row.invoiceNumber)">
                        <mat-icon>print</mat-icon>
                        <span>Print Receipt</span>
                    </button>

                    <a mat-menu-item href="{{row.receiptUrl}}" target="_blank">
                        <mat-icon>receipt_long</mat-icon>
                        <span>ZRA Receipt</span>
                    </a>
                    <button mat-menu-item (click)="issueRefund(row)" *ngIf="row.transactionType===0">
                        <mat-icon>undo</mat-icon>
                        <span>Credit Note</span>
                    </button>
                    <button mat-menu-item (click)="debitNote(row)" *ngIf="row.transactionType===0">
                        <mat-icon>redo</mat-icon>
                        <span>Debit Note</span>
                    </button>

                </mat-menu>


            </mat-cell>


        </ng-container>




        <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;">
        </mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[10, 25, 100]" [length]="resultsLength" showFirstLastButtons></mat-paginator>
</div>