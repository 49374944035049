import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { disable, ReactiveFormConfig, RxFormBuilder, RxwebValidators } from '@rxweb/reactive-form-validators';
import { PurchasedItem } from 'src/app/models/inventory';
import { EditProductDialogComponent } from '../../admin/dialogs/edit-product-dialog/edit-product-dialog.component';

@Component({
  selector: 'app-edit-purchased-item',
  templateUrl: './edit-purchased-item.component.html',
  styleUrls: ['./edit-purchased-item.component.scss']
})
export class EditPurchasedItemComponent implements OnInit {
  title: string;
  form: FormGroup;
  purchasedItem: PurchasedItem;

  constructor(
    private fb: RxFormBuilder,
    private dialogRef: MatDialogRef<EditProductDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.purchasedItem = data;
  }

  ngOnInit(): void {
    ReactiveFormConfig.set({
      "validationMessage": {
        "required": "This field is required",
        "numeric": "Please enter a numeric value",
        "noDecimal": "Please enter a valid number"
      }
    });
    this.form = this.fb.group({
      name: [this.purchasedItem.product.name],
      description: [this.purchasedItem.product.description],
      quantity: [this.purchasedItem.quantity, [RxwebValidators.required(),
      RxwebValidators.numeric({ allowDecimal: false, isFormat: true, messageKey: 'noDecimal' })]],
      price: [this.purchasedItem.product.price]
    });
    // this.form.controls.name.disable();
    // this.form.controls.description.disable();
    // this.form.controls.price.disable();
  }

  save() {
    if (this.form.valid) {
      this.purchasedItem.product.price = this.form.controls['price'].value;
      this.purchasedItem.quantity = Number(this.form.controls['quantity'].value);
      this.dialogRef.close(this.purchasedItem);
    }
    else {
      this.form.markAllAsTouched();
    }
  }

  close() {
    this.dialogRef.close();
  }

  removeItem() {
    this.purchasedItem.quantity = 0;
    this.dialogRef.close(this.purchasedItem);
  }

}
