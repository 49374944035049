import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PurchasedItem } from 'src/app/models/inventory';
import { Sales } from 'src/app/models/sales';
import { SalesTransaction } from 'src/app/models/salesTransaction';

@Component({
  selector: 'app-terminal',
  templateUrl: './terminal.component.html',
  styleUrls: ['./terminal.component.scss']
})
export class TerminalComponent implements OnInit {

  public salesTransaction;
  public terminalParameters: Sales;

  constructor(
    private router: Router
  ) {
    const data = this.router.getCurrentNavigation().extras.state?.data;
    if (data) {
      console.log('debit note transaction!');
      this.terminalParameters = data;
    }
  }

  ngOnInit(): void {
  }

  printEvent(transaction: SalesTransaction) {
    this.salesTransaction = transaction;
  }


}
