<div *ngIf="salesTransaction" fxLayout="column" id="invoice-POS" class="{{printStyle}}">



    <h3 fxLayoutAlign="center center">{{salesTransaction.esd.TaxpayerName}}</h3>
    <small fxLayoutAlign="center center">{{salesTransaction.esd.Addreess}}</small>
    <small fxLayoutAlign="center center">TPIN: {{salesTransaction.esd.TPIN}}</small>
    <small fxLayoutAlign="center center">Date: {{salesTransaction.response.ESDTime}}</small>
    <small fxLayoutAlign="center center">Invoice Code:{{salesTransaction.response.InvoiceCode}}</small>
    <small fxLayoutAlign="center center">Invoice Number:{{salesTransaction.response.InvoiceNumber}}</small>
    <b *ngIf="copyInvoice" fxLayoutAlign="center center">Copy Tax Invoice </b>
    <b *ngIf="!copyInvoice" fxLayoutAlign="center center">Tax Invoice </b>
    <!-- Refund Information -->
    <div *ngIf="salesTransaction.transactionType!=0">
        <div fxLayoutAlign="center center">

            <b *ngIf="salesTransaction.transactionType===1">Credit Note</b>
            <b *ngIf="salesTransaction.transactionType===2">Debit Note</b>
            &nbsp;
            <b *ngIf="salesTransaction.transactionType===1">{{salesTransaction.changeDue | sageCurrency}}</b>
            <b *ngIf="salesTransaction.transactionType===2">{{salesTransaction.total | sageCurrency}}</b>


        </div>
    </div>
    <!-- <h2 fxLayoutAlign="center center">Purchased Items</h2> -->
    <!-- Purchased Items Header -->
    <br>
    <div fxLayoutAlign="start center" fxLayout="row">
        <div fxLayout="row" fxFlex="60" fxLayoutAlign="start center">
            <b>Item</b>
        </div>

        <div fxFlex="10" fxLayoutAlign="start center">
            <!-- <b>Quantity</b> -->
        </div>
        <div fxFlex="10" fxLayoutAlign="start center">
            <b>Rate</b>
        </div>
        <div fxLayoutAlign="end center" fxFlex>
            <b>Price</b>
        </div>
    </div>
    <div *ngFor="let item of salesTransaction?.purchasedItems" class="product" fxLayoutAlign="start center">

        <!-- Purchased Items Data Rows -->

        <div fxLayout="row" fxFlex="60" fxLayoutAlign="start center">
            <small>{{ item.product.name }}</small>
        </div>

        <div fxFlex="10" fxLayoutAlign="start center">
            <div><small>x {{ item.quantity }}</small> </div>
        </div>
        <div fxFlex="10" fxLayoutAlign="start center">
            <small>{{ getTaxName(item.product.taxCodes) }}</small>
        </div>
        <div fxLayoutAlign="end center" fxFlex>
            <!-- <small>{{ item.product.price * item.quantity | sageCurrency }}</small> -->
            <small>{{getTaxInclusivePrice(item) | sageCurrency}}</small>
        </div>

    </div>
    <!-- Taxes -->

    <b>Taxes</b>
    <div *ngFor="let tax of salesTransaction?.response.TaxItems">
        <div fxLayout="row" fxFlex="10" fxLayoutAlign="start center">
            <small>{{ tax.TaxLabel }}</small>
        </div>

        <div fxLayout="row" fxFlex="60" fxLayoutAlign="start center">
            <small>{{ tax.CategoryName }}</small>
        </div>
        <div fxLayout="row" fxFlex="10" fxLayoutAlign="start center">
            <small>{{ tax.Rate * 100 }}%</small>
        </div>

        <div fxLayoutAlign="end center" fxFlex>
            <small>{{ tax.TaxAmount | sageCurrency }}</small>
        </div>

    </div>
    <!-- Total VAT -->
    <br>
    <div fxLayoutAlign="start center">
        <div fxFlex="40" fxLayout="row" fxLayoutAlign="start center">
            <b>Total VAT</b>
        </div>
        <div fxFlex fxLayoutAlign="end center">
            <b>{{salesTransaction.totalVAT | sageCurrency}}</b>
        </div>
    </div>

    <!-- Final Total -->

    <div fxLayoutAlign="start center">
        <div fxFlex="40" fxLayout="row" fxLayoutAlign="start center">
            <b>Total Amount</b>
        </div>
        <div fxFlex fxLayoutAlign="end center">
            <b>{{salesTransaction.total | sageCurrency}}</b>
        </div>
    </div>
    <mat-divider></mat-divider>
    <!-- Cash and Change -->
    <br>
    <div *ngIf="salesTransaction.transactionType===0">
        <div fxLayoutAlign="start center">
            <div fxFlex="30" fxLayout="row" fxLayoutAlign="start center">
                {{salesTransaction.paymentType.name}}
            </div>
            <div fxFlex fxLayoutAlign="end center">
                {{salesTransaction.tendered | sageCurrency}}
            </div>
        </div>
        <div fxLayoutAlign="start center" *ngIf="salesTransaction.paymentType.id === 0">
            <div fxFlex="30" fxLayout="row" fxLayoutAlign="start center">
                Change
            </div>
            <div fxFlex fxLayoutAlign="end center">
                {{salesTransaction.changeDue | sageCurrency}}
            </div>
        </div>
    </div>

    <div *ngIf="salesTransaction.transactionType!=0">
        <div fxFlex="50" fxLayout="row" fxLayoutAlign="start center">
            Original Invoice Num:
        </div>
        <div fxFlex fxLayoutAlign="end center">
            {{salesTransaction.OriginalInvoiceNumber }}
        </div>
    </div>
    <div *ngIf="salesTransaction.transactionType!=0">
        <div fxFlex="50" fxLayout="row" fxLayoutAlign="start center">
            Original Invoice Code:
        </div>
        <div fxFlex fxLayoutAlign="end center">
            {{salesTransaction.OriginalInvoiceCode }}
        </div>
    </div>
    <div *ngIf="salesTransaction.transactionType!=0">
        <div fxFlex="50" fxLayout="row" fxLayoutAlign="start start">
            <small *ngIf="salesTransaction.transactionType===1">Return Reason:</small>
            <small *ngIf="salesTransaction.transactionType===2">Memo:</small>
        </div>
        <div fxFlex fxLayoutAlign="end start">
            <small>{{salesTransaction.refundReason }}</small>
        </div>
    </div>

    <br>
    <!-- Buyer Info -->
    <div *ngIf="salesTransaction.customer">
        <div fxLayoutAlign="start center">
            <div fxFlex="30" fxLayout="row" fxLayoutAlign="start center">
                <small>Cashier</small>
            </div>
            <div fxFlex fxLayoutAlign="end center">
                <small>{{salesTransaction.cashier}}</small>
            </div>
        </div>
        <div fxLayoutAlign="start center">
            <div fxFlex="30" fxLayout="row" fxLayoutAlign="start center">
                <small>Buyer Name</small>
            </div>
            <div fxFlex fxLayoutAlign="end center">
                <small>{{salesTransaction.customer.firstName}} {{salesTransaction.customer.lastName}}</small>
            </div>
        </div>
        <div fxLayoutAlign="start center">
            <div fxFlex="30" fxLayout="row" fxLayoutAlign="start center">
                <small>Buyer TPIN</small>
            </div>
            <div fxFlex fxLayoutAlign="end center">
                <small>{{salesTransaction.customer.TPIN}}</small>
            </div>
        </div>
        <div fxLayoutAlign="start center">
            <div fxFlex="50" fxLayout="row" fxLayoutAlign="start center">
                <small>Buyer Tax Acc Name</small>
            </div>
            <div fxFlex fxLayoutAlign="end center">
                <small>{{salesTransaction.customer.taxAccountName}}</small>
            </div>
        </div>
        <div fxLayoutAlign="start center" *ngIf="salesTransaction.customer.lpo">
            <div fxFlex="50" fxLayout="row" fxLayoutAlign="start center">
                <small>Local Purchase Order:</small>
            </div>
            <div fxFlex fxLayoutAlign="end center">
                <small>{{salesTransaction.customer.lpo}}</small>
            </div>
        </div>
    </div>


    <!-- Terminal ID, Fiscal Code, Sale Type -->
    <br>
    <div fxLayout="column" fxLayoutAlign="center center">


        <div fxLayoutAlign="start center">

            <small>Terminal ID: {{salesTransaction.response.TerminalID}}</small>


        </div>
        <div fxLayoutAlign="start center">

            <small>Fiscal Code: {{salesTransaction.response.FiscalCode}}</small>


        </div>
        <div fxLayoutAlign="start center">

            <small>Sale Type: {{saleType(salesTransaction?.saleType)}}</small>


        </div>

        <br>
        <div fxFlex fxLayoutAlign="center center">
            **** FISCAL RECEIPT ****
        </div>


    </div>

    <div fxFlex fxLayoutAlign="center center">
        <qrcode-svg class="qrCode" [value]="salesTransaction.response.VerificationUrl">
        </qrcode-svg>
    </div>

</div>