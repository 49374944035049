<div class="container mat-elevation-z8 main-container">

    <h2>Print Settings</h2>

    <h3>Paper Size</h3>

    <mat-form-field>
        <mat-select [(ngModel)]="printStyle" (selectionChange)="setPrintStyle()">
            <mat-option value="s80">Receipt 80mm</mat-option>
            <mat-option value="s180">Letter 180mm</mat-option>
            <mat-option value="s210">A4 210mm</mat-option>
        </mat-select>
    </mat-form-field>

</div>