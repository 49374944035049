import { Tax } from './models/inventory';

const mtvRRPAmount = 4 / 29;


const roundTo = function (num: number, places: number) {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
};

export function calculateTotalTax(salePrice: number, taxes: Tax[], rrp?: number): number {
    if (!taxes || taxes.length === 0) return 0;
    let totalTaxes = 0;

    taxes.forEach(t => {
        if (t.code === 'B' && salePrice + (salePrice * t.amount) < rrp) {

            totalTaxes += rrp * mtvRRPAmount;
        }
        else {
            totalTaxes += salePrice * t.amount
        }

    });
    return roundTo(totalTaxes, 2);
}

export function calculateTaxInclusivePrice(salePrice: number, taxes: Tax[], rrp?: number): number {
    if (!taxes || taxes.length === 0) return salePrice;
    const totalTaxes = calculateTotalTax(salePrice, taxes, rrp);
    return salePrice + totalTaxes;
}


