import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Product, Tax, Category } from 'src/app/models/inventory';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { InventoryService } from 'src/app/services/inventory.service';
import { EditProductDialogComponent } from '../dialogs/edit-product-dialog/edit-product-dialog.component';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { forkJoin, fromEvent, merge, observable, of } from 'rxjs';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { catchError, debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  //changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;

  @ViewChild('input') input: ElementRef;

  resultsLength = 0;
  filterValue = '';
  products: Product[] = [];
  displayedColumns: string[] = [];
  private dialogConfig: MatDialogConfig;
  taxList: Tax[];
  categoryList: Category[];

  constructor(
    private inventoryService: InventoryService,
    private dialog: MatDialog,
    private snackbarService: SnackbarService
  ) { }

  ngOnInit(): void {
    this.displayedColumns = [
      'name',
      'description',
      'price',
      'rrp',
      'quantity',
      'categoryName',
      'taxCodes',
      'actions',
    ];

    forkJoin([this.inventoryService.getTaxes(),
    this.inventoryService.getCategories(),
    ]).subscribe(([taxes, categories]) => {
      this.taxList = taxes;
      this.categoryList = categories;
      this.initSearchSort();
    });

    this.dialogConfig = new MatDialogConfig();
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.width = '30rem';
    this.dialogConfig.maxHeight = '85vh';
  }

  private initSearchSort() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {

          return this.inventoryService.getProductsPaged(this.paginator.pageIndex, this.paginator.pageSize,
            this.sort.active, this.sort.direction, this.filterValue);
          ;
        }),
        map(results => {

          this.resultsLength = results.totalItems;
          return results.data
        }),
        catchError((err) => {

          console.log('An error occurred', err);
          return of([]);
        })
      ).subscribe((results) => { this.products = results; });

    // wire up search input to debounce time
    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(350),
        distinctUntilChanged(),
        tap((event: KeyboardEvent) => {
          this.applyFilter(event);
        })

      )
      .subscribe();
  }


  addItem() {
    this.dialogConfig.data = { product: null, taxList: this.taxList, categories: this.categoryList };
    const dialogRef = this.dialog.open(
      EditProductDialogComponent,
      this.dialogConfig
    );
    dialogRef.componentInstance.saveData.subscribe(data => {
      if (data) {
        this.inventoryService.createProduct(data).subscribe(
          () => {
            dialogRef.close();
            this.snackbarService.showSuccessMessage('Product added');
            this.refreshData();
            //this.table.renderRows();
          },
          (err) => {
            console.log(`error:`, err);
            let msg = '';
            if (err.error && err.error[0].errorCode === 19) {
              msg = 'Product code already exists';
            }
            else {
              msg = 'Could not save Product';
            }
            this.snackbarService.showErrorMessage(msg);
          }
        );
      }
    });
  }

  editItem(item: Product) {
    this.dialogConfig.data = { product: item, taxList: this.taxList, categories: this.categoryList };;
    const dialogRef = this.dialog.open(
      EditProductDialogComponent,
      this.dialogConfig
    );
    dialogRef.componentInstance.saveData.subscribe((data) => {
      if (data) {
        this.inventoryService.updateProduct(item.id, data).subscribe(
          () => {
            dialogRef.close();
            this.snackbarService.showSuccessMessage('Product edited successfully');
            this.refreshData();
          },
          (err: HttpErrorResponse) => {
            console.log(`error:`, err);
            let msg = '';
            if (err.error[0].errorCode === 19) {
              msg = 'Product code already exists';
            }
            else if (err.status === 401) {
              msg = 'Session expired, please login again';
              dialogRef.close();
            }

            else {
              msg = 'Could not save Product';
            }
            this.snackbarService.showErrorMessage(msg);
          }
        );
      }
    });
  }

  deleteItem(item: Product) {

    this.dialogConfig.autoFocus = false;
    this.dialogConfig.data = {
      title: 'Delete Product',
      description: `Are you sure you want to delete "${item.name}" ?`,
      okLabel: 'Delete',
      cancelLabel: 'Cancel',
    };
    const dialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.inventoryService.deleteProduct(item.id).subscribe(
          () => {
            this.snackbarService.showSuccessMessage('Product deleted');
            this.refreshData();
          },
          (err) => {
            this.snackbarService.showErrorMessage('Error: could not delete product');
            // handle error here
          }
        );
      }
    });
  }

  getTaxName(id: Tax[]): string {
    var taxes = '';
    id.forEach(x => {
      const taxName = this.taxList.find((i) => i.id === x.id);
      taxName ? taxes += taxName.code + '   ' : '';
    })

    return taxes;
  }

  refreshData(resetPage?: boolean) {
    if (resetPage) this.paginator.pageIndex = 0;
    this.inventoryService.getProductsPaged(this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction, this.filterValue).subscribe((results) => {
      this.products = results.data;
      this.resultsLength = results.totalItems;
    });
  }

  pageEvent(event) {
    console.log(`page event: length:${event.length}, pageIndex:${event.pageIndex}, pageSize:${event.pageSize}`);
    if (event.sortChange) {
      console.log('sort changed');
    }
    this.refreshData();
  }

  // using this for performance gains on big lists
  trackByProductId(index: number, product: any): string {
    return product.id;
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.refreshData(true);
  }
}
