import { Component, OnInit, ViewChild } from '@angular/core';
import { InventoryService } from 'src/app/services/inventory.service';
import { Category, Product, Tax } from '../../../models/inventory';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EditCategoryDialogComponent } from '../dialogs/edit-category-dialog/edit-category-dialog.component';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { forkJoin } from 'rxjs';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;

  dataSource: any;
  displayedColumns: string[] = [];
  private dialogConfig: MatDialogConfig;
  taxList: Tax[];

  constructor(
    private inventoryService: InventoryService,
    private dialog: MatDialog,
    private snackbarService: SnackbarService
  ) { }

  ngOnInit(): void {
    this.displayedColumns = ['name', 'description', 'taxCodes', 'actions'];
    forkJoin([
      this.inventoryService.getTaxes(),
      this.inventoryService.getCategories(),
    ]).subscribe(([taxes, categories]) => {
      this.taxList = taxes;
      this.dataSource = new MatTableDataSource(categories);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });

    this.dialogConfig = new MatDialogConfig();
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.width = '20rem';
    this.dialogConfig.maxHeight = '85vh';
  }

  addItem() {
    this.dialogConfig.data = { category: null, taxList: this.taxList };
    this.dialogConfig.data.taxList = this.taxList;
    const dialogRef = this.dialog.open(
      EditCategoryDialogComponent,
      this.dialogConfig
    );
    dialogRef.componentInstance.saveData.subscribe(data => {
      if (data) {
        this.inventoryService.createCategory(data).subscribe(
          () => {
            dialogRef.close();
            this.snackbarService.showSuccessMessage('Category added');
            this.refreshData();
            //this.table.renderRows();
          },
          (err) => {
            console.log(`error:`, err);
            let msg = '';
            if (err.error[0].errorCode === 19) {
              msg = 'Category name already exists';
            }
            else {
              msg = 'Could not save Category';
            }
            this.snackbarService.showErrorMessage(msg);
          }
        );
      }
    });
  }

  editItem(item: Category) {
    this.dialogConfig.data = { category: item, taxList: this.taxList };
    this.dialogConfig.data.taxList = this.taxList;
    const dialogRef = this.dialog.open(
      EditCategoryDialogComponent,
      this.dialogConfig
    );
    dialogRef.componentInstance.saveData.subscribe((data) => {
      if (data) {
        this.inventoryService.updateCategory(item.id, data).subscribe(
          () => {
            dialogRef.close();
            this.snackbarService.showSuccessMessage('Category edited successfully');
            this.refreshData();
          },
          (err) => {
            console.log(`error:`, err);
            let msg = '';
            if (err.error[0].errorCode === 19) {
              msg = 'Category name already exists';
            }
            else {
              msg = 'Could not save Category';
            }
            this.snackbarService.showErrorMessage(msg);
          }
        );
      }
    });
  }

  deleteItem(item: Category) {
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.data = {
      title: 'Delete Category',
      description: `Are you sure you want to delete "${item.name}" ?`,
      okLabel: 'Delete',
      cancelLabel: 'Cancel',
    };
    const dialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.inventoryService.deleteCategory(item.id).subscribe(
          () => {
            this.snackbarService.showSuccessMessage('Category deleted');
            this.refreshData();
          },
          (err) => {
            this.snackbarService.showErrorMessage('Error: could not delete category');
          }
        );
      }
    });
  }

  getTaxName(id: Tax[]): string {
    var taxes = '';
    id.forEach(x => {
      const taxName = this.taxList.find((i) => i.id === x.id);
      taxName ? taxes += taxName.code + '   ' : '';
    })

    return taxes;
  }

  refreshData() {
    this.inventoryService.getCategories().subscribe((data) => {
      this.dataSource.data = data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
}
