import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { InventoryService } from 'src/app/services/inventory.service';
import { EditTaxesDialogComponent } from '../dialogs/edit-taxes-dialog/edit-taxes-dialog.component';
import { Tax } from 'src/app/models/inventory';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { EsdService } from 'src/app/services/esd.service';
import { ESD } from 'src/app/models/esd';
import { forkJoin, Observable } from 'rxjs';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-taxes',
  templateUrl: './taxes.component.html',
  styleUrls: ['./taxes.component.scss']
})
export class TaxesComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;

  dataSource: any;
  displayedColumns: string[] = [];
  private dialogConfig: MatDialogConfig;

  constructor(
    private inventoryService: InventoryService,
    private esdService: EsdService,
    private dialog: MatDialog,
    private snackbarService: SnackbarService
  ) { }

  ngOnInit(): void {
    this.displayedColumns = [
      'name',
      // 'description',
      'amount',
      'code',
      'effectiveDate',
      'expiredDate',
      'actions',
    ];

    this.inventoryService.getTaxes().subscribe(result => {
      this.dataSource = new MatTableDataSource(result);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
    this.dialogConfig = new MatDialogConfig();
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.maxHeight = '85vh';
  }

  addItem() {

    this.dialogConfig.data = {};
    const dialogRef = this.dialog.open(EditTaxesDialogComponent, this.dialogConfig);
    dialogRef.componentInstance.saveData.subscribe(data => {
      if (data) {
        this.inventoryService.createTax(data).subscribe(
          () => {
            dialogRef.close();
            this.snackbarService.showSuccessMessage('Tax added');
            this.refreshData();
            //this.table.renderRows();
          },
          (err) => {
            console.log(`error:`, err);
            let msg = '';
            if (err.error[0].errorCode === 19) {
              msg = 'Tax name or label already exists';
            }
            else {
              msg = 'Could not save Tax';
            }
            this.snackbarService.showErrorMessage(msg);
          }
        );
      }
    });
  }

  editItem(item: Tax) {
    this.dialogConfig.data = item;
    const dialogRef = this.dialog.open(EditTaxesDialogComponent, this.dialogConfig);
    dialogRef.componentInstance.saveData.subscribe((data) => {
      if (data) {
        this.inventoryService.updateTax(item.id, data).subscribe(
          () => {
            dialogRef.close();
            this.snackbarService.showSuccessMessage('Tax edited successfully');
            this.refreshData();
          },
          (err) => {
            console.log(`error:`, err);
            let msg = '';
            if (err.error[0].errorCode === 19) {
              msg = 'Tax name or label already exists';
            }
            else {
              msg = 'Could not save Tax';
            }
            this.snackbarService.showErrorMessage(msg);
          }
        );
      }
    });

  }

  deleteItem(item: Tax) {
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.data = {
      title: 'Delete Category',
      description: `Are you sure you want to delete the "${item.name}" tax?`,
      okLabel: 'Delete',
      cancelLabel: 'Cancel'
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, this.dialogConfig);
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.inventoryService.deleteTax(item.id).subscribe(
          () => {
            this.snackbarService.showSuccessMessage('Tax deleted');
            this.refreshData();
          },
          err => {
            this.snackbarService.showErrorMessage('Error: tax could not be deleted');
          }
        );
      }
    });

  }



  refreshData() {
    this.inventoryService.getTaxes().subscribe(
      (data) => {
        this.dataSource.data = data;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    );
  }

  async importESDTaxes() {
    this.esdService.getESDStatus(true).subscribe(result => {
      let batchCreate = [];
      const taxCreateList = this.getImportedTaxList(result);
      console.log('tax list length:', taxCreateList.length);
      forkJoin(
        taxCreateList.map(p => {
          return this.inventoryService.createTax(p);
        })).subscribe(r => {
          this.refreshData();
        });
    }
    );

  }

  private getImportedTaxList(result: ESD): Tax[] {
    let importedTaxes: Tax[] = [];
    for (let i = 0; i < result.TaxInfo.length; i++) {
      for (let j = 0; j < result.TaxInfo[i].Category.length; j++) {
        const tax = {
          id: null,
          name: result.TaxInfo[i].Category[j].TaxName,
          code: result.TaxInfo[i].Category[j].TaxLabel,
          description: '',
          amount: result.TaxInfo[i].Category[j].TaxRate,
          active: true,
          effectiveDate: result.TaxInfo[i].Category[j].EffectiveDate,
          expiredDate: result.TaxInfo[i].Category[j].ExpiredDate
        };
        importedTaxes.push(tax);

      }
    }
    return importedTaxes;
  }

}
