<h1>Sales Dashboard</h1>
<div class="container">
    <div fxLayout="row" fxLayoutAlign="stretch center">
        <div fxFlex="30" fxLayoutAlign="start center">
            <mat-button-toggle-group name="reportType" [value]="totalType">
                <mat-button-toggle value="daily" (click)="setReportType('daily')">Daily</mat-button-toggle>
                <mat-button-toggle value="monthly" (click)="setReportType('monthly')">Monthly</mat-button-toggle>
                <mat-button-toggle value="yearly" (click)="setReportType('yearly')">Yearly</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div fxFlex="20" fxFlex fxLayoutAlign="center end">
            {{titleTimeFrame}} {{titleTransactionType}}
        </div>
        <div fxFlex="50" fxLayoutAlign="center center">
            <mat-chip-list>
                <mat-chip (click)="setTransactionType(-1)"><small>All</small></mat-chip>
                <mat-chip (click)="setTransactionType(0)" color="primary" selected><small>Sales</small> </mat-chip>
                <mat-chip (click)="setTransactionType(1)" color="warn" selected><small>Credit Notes</small></mat-chip>
                <mat-chip (click)="setTransactionType(2)" color="accent" selected><small>Debit Notes</small></mat-chip>
            </mat-chip-list>
        </div>

    </div>
</div>



<div class="container mat-elevation-z8">
    <mat-table [dataSource]="sales" matSort matSort matSortActive="date" matSortDirection="desc">

        <ng-container matColumnDef="date">
            <mat-header-cell fxFlex="25" *matHeaderCellDef mat-sort-header> Date </mat-header-cell>
            <mat-cell fxFlex="25" *matCellDef="let row" [style.color]="row.color" data-label="date">
                {{formatDate(row.date)}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="salesTotal">
            <mat-header-cell fxFlex="20" *matHeaderCellDef> Total Sales </mat-header-cell>
            <mat-cell fxFlex="20" *matCellDef="let row" [style.color]="row.color" data-label="salesTotal">
                {{row.salesTotal | sageCurrency}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="productTotal">
            <mat-header-cell fxFlex="20" *matHeaderCellDef> Product Total </mat-header-cell>
            <mat-cell fxFlex="20" *matCellDef="let row" [style.color]="row.color" data-label="productTotal">
                {{row.productTotal | sageCurrency}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="taxTotal">
            <mat-header-cell fxFlex="20" *matHeaderCellDef> Tax Total </mat-header-cell>
            <mat-cell fxFlex="20" *matCellDef="let row" [style.color]="row.color" data-label="taxTotal">
                {{row.taxTotal | sageCurrency}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="transactions">
            <mat-header-cell fxFlex="10" *matHeaderCellDef> # Transactions </mat-header-cell>
            <mat-cell fxFlex="10" *matCellDef="let row" [style.color]="row.color" data-label="transactions">
                {{row.transactions}}
            </mat-cell>
        </ng-container>


        <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;">
        </mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[10, 25, 100]" [length]="resultsLength" showFirstLastButtons></mat-paginator>
</div>