<div>
  <div fxLayout="row" fxLayoutAlign="start start">
    <button mat-raised-button color="accent" (click)="addItem()" fxLayoutAlign="start end">
      <mat-icon>add</mat-icon>
      Add Product
    </button>
  </div>
  <div fxFlex="80" fxLayoutAlign="start center" fxFlexOffset="2">
    <mat-icon>search</mat-icon>
    <mat-form-field fxFlex>
      <mat-label>Search for products</mat-label>
      <input matInput #input>
    </mat-form-field>
  </div>
</div>

<div class="mt-2 mat-elevation-z8">

  <mat-table [dataSource]="products" matSort matSortActive="name" matSortDirection="asc">

    <ng-container matColumnDef="name">
      <mat-header-cell fxFlex="15" *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
      <mat-cell fxFlex="15" *matCellDef="let row" data-label="name">
        <div fxLayout="column">
          <div>
            {{row.name}}
          </div>
          <div>
            <small class="productCode">{{row.productCode}}</small>
          </div>
        </div>
      </mat-cell>

    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell fxFlex="30" *matHeaderCellDef mat-sort-header> Description </mat-header-cell>
      <mat-cell class="paddedCell" fxFlex="30" *matCellDef="let row" data-label="description"> {{row.description}}
      </mat-cell>
    </ng-container>

    <!-- <ng-container matColumnDef="productCode">
      <mat-header-cell fxFlex="10" *matHeaderCellDef mat-sort-header> Code </mat-header-cell>
      <mat-cell fxFlex="5" *matCellDef="let row" [style.color]="row.color" data-label="productCode">
        <small>{{row.productCode}}</small> </mat-cell>
    </ng-container> -->

    <ng-container matColumnDef="price">
      <mat-header-cell fxFlex="10" *matHeaderCellDef mat-sort-header> Price </mat-header-cell>
      <mat-cell fxFlex="10" *matCellDef="let row" [style.color]="row.color" data-label="price">
        {{row.price | sageCurrency}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="rrp">
      <mat-header-cell fxFlex="10" *matHeaderCellDef mat-sort-header> RRP </mat-header-cell>
      <mat-cell fxFlex="10" *matCellDef="let row" [style.color]="row.color" data-label="rrp">
        {{row.rrp | sageCurrency}}
      </mat-cell>
    </ng-container>

    <!-- <ng-container matColumnDef="cost">
      <mat-header-cell fxFlex="10" *matHeaderCellDef mat-sort-header> Cost </mat-header-cell>
      <mat-cell  fxFlex="20" *matCellDef="let row" [style.color]="row.color" data-label="cost"> {{row.cost}} </mat-cell>
    </ng-container> -->

    <ng-container matColumnDef="quantity">
      <mat-header-cell fxFlex="10" *matHeaderCellDef mat-sort-header> Quantity </mat-header-cell>
      <mat-cell fxFlex="10" fxLayoutAlign="start center" *matCellDef="let row" [style.color]="row.color"
        data-label="price"> {{row.quantity}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="categoryName">
      <mat-header-cell fxFlex="10" *matHeaderCellDef mat-sort-header> Category </mat-header-cell>
      <mat-cell fxFlex="10" *matCellDef="let row" [style.color]="row.color" data-label="categoryName">
        {{row.categoryName}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="taxCodes">
      <mat-header-cell fxFlex="5" *matHeaderCellDef> Tax Codes </mat-header-cell>
      <mat-cell fxFlex="5" *matCellDef="let row" [style.color]="row.color" data-label="taxCodes">
        {{getTaxName(row.taxCodes)}} </mat-cell>
    </ng-container>

    <!-- actions -->
    <ng-container matColumnDef="actions">
      <mat-header-cell fxFlex="14" fxLayoutAlign="end center" *matHeaderCellDef>

      </mat-header-cell>
      <mat-cell fxFlex="14" fxLayoutAlign="end center" *matCellDef="let row; let i=index;">
        <button mat-icon-button color="primary" (click)="editItem(row)">
          <mat-icon aria-label="Edit">edit</mat-icon>
        </button>

        <button mat-icon-button color="basic" (click)="deleteItem(row)">
          <mat-icon aria-label="Delete">delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;">
    </mat-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[10, 25, 100]" [length]="resultsLength" showFirstLastButtons></mat-paginator>
</div>