<div class="signup-background">
  <div class="container h-100">
    <div class="row h-100 align-items-center justify-content-center ">

      <div class="col signup-container bg-accent-light text-center shadow">
        <div class="text-center">
          <h1>Z-POS</h1>
        </div>
        <form [formGroup]="signinForm" (ngSubmit)="signIn()">
          <div class="row h-25 align-items-start justify-content-center">
            <img width="80px" src="assets/images/illustrations/sage.png" alt="" />
          </div>
          <div class="row h-50 align-items-center ">
            <div class="col">
              <mat-form-field class="signup-field">
                <input matInput placeholder="Username" formControlName="username" required>
                <mat-error *ngFor="let errorMessage of signinForm.controls.username['errorMessages']">
                  {{errorMessage}}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="signup-field">
                <input matInput type="password" placeholder="Password" formControlName="password" required />
                <mat-error *ngFor="let errorMessage of signinForm.controls.password['errorMessages']">
                  {{errorMessage}}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row h-25 align-items-end">
            <div class="col p-2">
              <button mat-raised-button color="primary" type="submit" class="p-2 signup-button">Sign In</button>
              <div *ngIf="errorMsg!=''" class="mat-error" [@animate]="{
                value: '*',
                params: { y: '50px', opacity: '0', delay: '100ms', duration: '100ms' }
              }">
                <div class="row p-2 h-100 align-items-center justify-content-center">
                  <mat-icon>warning</mat-icon>
                  <span class="spacer"></span>
                  {{errorMsg}}
                </div>

              </div>
            </div>

          </div>

        </form>

      </div>
    </div>
  </div>

</div>