import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Product, Category, Tax } from 'src/app/models/inventory';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InventoryService } from 'src/app/services/inventory.service';
import { ReactiveFormConfig, RxFormBuilder, RxwebValidators } from '@rxweb/reactive-form-validators';
import { BehaviorSubject } from 'rxjs';
import * as taxHelpers from '../../../../taxHelpers';

@Component({
  selector: 'app-edit-product-dialog',
  templateUrl: './edit-product-dialog.component.html',
  styleUrls: ['./edit-product-dialog.component.scss'],
})
export class EditProductDialogComponent implements OnInit {
  title: string;
  form: FormGroup;
  productData: Product;
  taxList: Tax[];
  categoryList: Category[];
  customProduct: boolean;
  saveData: BehaviorSubject<Product>;
  showRRP = false;
  finalSalePrice = 0;
  finalSalePriceVATLabel = '(VAT inclusive)';

  constructor(
    private fb: RxFormBuilder,
    private dialogRef: MatDialogRef<EditProductDialogComponent>,
    private inventoryService: InventoryService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.saveData = new BehaviorSubject<Product>(null);
    this.taxList = data.taxList;
    this.categoryList = data.categories;

    if (data.product) {
      this.productData = data.product;
      this.title = 'Edit Product';
    } else {
      this.title = 'Add Product';
      this.productData = <Product>{};
    }
    if (data.customProduct) this.customProduct = true;
  }

  ngOnInit(): void {
    const taxCode = this.productData.taxCodes ? this.productData.taxCodes[0] : null;
    ReactiveFormConfig.set({
      "validationMessage": {
        "required": "This field is required",
        "numeric": "Please enter a numeric value"
      }
    });
    this.form = this.fb.group({
      name: [this.productData.name, RxwebValidators.required()],
      description: [this.productData.description],
      price: [
        this.productData.price,
        [
          RxwebValidators.required(),
          RxwebValidators.numeric({ allowDecimal: true, isFormat: true })
        ],
      ],
      rrp: [this.productData.rrp, RxwebValidators.required()],
      cost: [
        this.productData.cost,
        [RxwebValidators.numeric({ allowDecimal: true, isFormat: true })],
      ],
      quantity: [this.productData.quantity, RxwebValidators.numeric()],
      categoryName: [this.productData.categoryName],
      taxCodes: [null, RxwebValidators.required()],
      productCode: [this.productData.productCode],
      priceVat: null
    });

    if (this.customProduct) this.form.controls['quantity'].setValue(1);

    // we need to set the default values to the multiselect drop down here
    this.form.controls['taxCodes'].setValue(this.productData.taxCodes);
    this.taxesChanged(); // fire this command to calculate Sales Vat Price
    this.updateFinalSalePrice();
  }

  categoryChange() {
    const c = this.categoryList.find(x => x.name === this.form.controls['categoryName'].value);
    const taxCode = c.taxCodes ? c.taxCodes[0] : null;
    this.form.controls['taxCodes'].setValue(c.taxCodes);
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    if (this.form.valid) {
      let p = <Product>{};
      p.name = this.form.controls['name'].value;
      p.description = this.form.controls['description'].value;
      p.categoryName = this.form.controls['categoryName'].value;
      p.productCode = this.form.controls['productCode'].value;
      p.price = this.form.controls['price'].value;
      p.rrp = this.showRRP ? this.form.controls['rrp'].value : null;
      p.cost = this.form.controls['cost'].value;
      p.quantity = this.form.controls['quantity'].value;
      p.taxCodes = this.form.controls['taxCodes'].value;
      this.saveData.next(p);
    }
    else {
      this.form.markAllAsTouched();
    }
  }

  updateSalesPrice(event: any) {
    if (!event.target.value) return;
    const taxes = (1 + this.calculateTaxes());
    const salesAmountVAT = parseFloat(event.target.value);
    const salesAmount = salesAmountVAT / taxes;
    this.form.controls['price'].setValue(this.twoDecimals(salesAmount));
    this.updateFinalSalePrice();
  }

  updateSalesVatPrice(event: any) {
    if (!event.target.value) return;
    const taxes = this.calculateTaxes();
    const salesAmount = parseFloat(event.target.value);
    const salesAmountVAT = salesAmount + (salesAmount * taxes);
    this.form.controls['priceVat'].setValue(this.twoDecimals(salesAmountVAT));
    this.updateFinalSalePrice();
  }

  updateFinalSalePrice() {
    const taxAmount = taxHelpers.calculateTotalTax(
      this.form.controls['price'].value,
      this.form.controls['taxCodes'].value,
      this.form.controls['rrp'].value
    );
    this.finalSalePrice = this.twoDecimals(this.form.controls['price'].value + taxAmount);
    if (this.customProduct) this.finalSalePrice = this.twoDecimals(this.finalSalePrice * this.form.controls['quantity'].value);
    console.log('calc tax:', taxAmount);
    if (this.form.controls['priceVat'].value >= this.form.controls['rrp'].value) {
      this.finalSalePriceVATLabel = '(VAT inclusive)';
    }
    else {
      this.finalSalePriceVATLabel = '(using RRP calculation for VAT)';
    }
  }

  private calculateTaxes() {
    const taxes = this.form.controls['taxCodes'].value;
    console.log('taxes selected:', taxes);
    if (!taxes) return 0;
    let totalTaxes = 0;
    this.setRRP(false); // only show this field for MTV tax
    taxes.forEach(t => {
      const taxAmount = this.taxList.find(x => x.id === t.id);
      if (taxAmount.code === 'B') this.setRRP(true);
      totalTaxes += taxAmount.amount;
    });
    return totalTaxes;
  }

  setRRP(value: boolean) {
    this.showRRP = value;
    if (value) {
      console.log('setting productCode to required');
      this.form.controls['rrp'].setValidators(RxwebValidators.required());
      this.form.controls['productCode'].setValidators(RxwebValidators.required());
    }
    else {
      this.form.controls['rrp'].clearValidators();
      this.form.controls['productCode'].clearValidators();
    }
    this.form.controls['rrp'].updateValueAndValidity();
    this.form.controls['productCode'].updateValueAndValidity();
  }

  taxesChanged() {
    const taxes = this.calculateTaxes();
    const salesAmount = this.form.controls['price'].value;
    const salesAmountVAT = salesAmount + (salesAmount * taxes);
    this.form.controls['priceVat'].setValue(this.twoDecimals(salesAmountVAT));
    this.updateFinalSalePrice();
  }

  private twoDecimals(num: number): number {
    return parseFloat(num.toFixed(2));
  }

  // This is required for comparing objects on the multiple select control
  compareFunction(o1: any, o2: any) {
    if (!o1 || !o2) return;
    return (o1.id == o2.id);
  }
}
