import { DatePipe } from '@angular/common';
import { ViewChild } from '@angular/core';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { merge, of } from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { Sales } from 'src/app/models/sales';
import { SalesService } from 'src/app/services/sales.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;
  displayedColumns: string[] = [];
  resultsLength = 0;
  sales: Sales[] = [];
  totalType = 'daily';
  transactionType = -1;
  titleTimeFrame = 'DAILY';
  titleTransactionType = 'All';

  constructor(
    private salesService: SalesService,
    private datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.displayedColumns = [
      'date',
      'salesTotal',
      'productTotal',
      'taxTotal',
      'transactions'
    ]
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          console.log(`pageIndex:${this.paginator.pageIndex}, size: ${this.paginator.pageSize}`);
          return this.salesService.getSales(this.paginator.pageIndex, this.paginator.pageSize,
            this.sort.active, this.sort.direction, this.totalType);
        }),
        map(results => {
          this.resultsLength = results.totalItems;
          return results.data
        }),
        catchError((err) => {
          console.log('An error occurred', err);
          return of([]);
        })
      ).subscribe((results) => {
        this.sales = results
      }
      );
  }

  refreshData() {
    this.salesService.getSales(this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction, this.totalType, this.transactionType).subscribe((results) => {
      this.resultsLength = results.totalItems;
      this.sales = results.data;
    },
      error => {
        console.log('error loading getSales');
      });
  }

  pageEvent(event) {
    console.log('page event called');
    this.refreshData();
  }

  setReportType(reportType: string) {
    this.totalType = reportType;
    this.paginator.pageIndex = 0;
    this.titleTimeFrame = reportType.toUpperCase();
    this.refreshData();
  }

  setTransactionType(t: number) {
    switch (t) {
      case -1: this.titleTransactionType = 'ALL';
        break;
      case 0: this.titleTransactionType = 'SALES';
        break;
      case 1: this.titleTransactionType = 'CREDIT NOTES';
        break;
      case 2: this.titleTransactionType = 'DEBIT NOTES';
        break;
    };
    this.paginator.pageIndex = 0;
    this.transactionType = t;
    this.refreshData();

  }

  formatDate(date: string): string {
    try {
      if (date.indexOf('-') > 0) {
        const month = parseInt(date.substr(0, 2)) - 1;
        const year = parseInt(date.substr(3, 4));
        const newDate = new Date(year, month);
        return this.datePipe.transform(newDate, 'MMM, yyyy');
      }
      else if (date.indexOf('/') > 0) return this.datePipe.transform(date, 'mediumDate');
      else {
        return date;
      }


    }
    catch (err) {
      console.log('error converting date:', err);
    }

  }

}
