import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReactiveFormConfig, RxFormBuilder, RxwebValidators } from '@rxweb/reactive-form-validators';
import { BehaviorSubject } from 'rxjs';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
import { EditCategoryDialogComponent } from '../edit-category-dialog/edit-category-dialog.component';

@Component({
  selector: 'app-edit-user-dialog',
  templateUrl: './edit-user-dialog.component.html',
  styleUrls: ['./edit-user-dialog.component.scss']
})
export class EditUserDialogComponent implements OnInit {
  title: string;
  form: FormGroup;
  userData: User;
  roleList = [];
  isEditing: boolean;
  changePassword: boolean;
  saveData: BehaviorSubject<User>;

  constructor(
    private fb: RxFormBuilder,
    private dialogRef: MatDialogRef<EditCategoryDialogComponent>,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.saveData = new BehaviorSubject<User>(null);
    if (data.user) {
      this.userData = data.user;
      this.title = 'Edit User';
      this.isEditing = true;
      this.changePassword = data.changePassword
    } else {
      this.title = 'Add User';
      this.userData = <User>{};
    }
    this.roleList = data.roleList;
  }

  ngOnInit(): void {
    ReactiveFormConfig.set({
      "validationMessage": {
        "required": "This field is required",
        "email": "Please enter a valid email address"
      }
    });
    this.form = this.fb.group({
      firstName: [this.userData.firstName, RxwebValidators.required()],
      lastName: [this.userData.lastName],
      username: [this.userData.username, RxwebValidators.required()],
      password: [null, RxwebValidators.required()],
      email: [this.userData.email, RxwebValidators.email()],
      roles: [null]
    });

    if (this.isEditing) {
      console.log('clearing validators');
      this.form.controls['password'].clearValidators();
    }
    // we need to set the default values to the multiselect drop down here
    this.form.controls['roles'].setValue(this.userData.roles);
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    if (this.form.valid) {
      let u = <User>{};
      u.firstName = this.form.controls['firstName'].value;
      u.lastName = this.form.controls['lastName'].value;
      u.username = this.form.controls['username'].value;
      u.email = this.form.controls['email'].value;
      u.password = this.form.controls['password'].value;
      u.roles = this.form.controls['roles'].value;
      if (!this.changePassword && this.isEditing) {
        delete u.password;
      }
      this.saveData.next(u);
    }
    else {
      this.form.markAllAsTouched();
    }
  }

  // This is required for comparing objects on the multiple select control
  compareFunction(o1: any, o2: any) {
    if (!o1 || !o2) return;
    return (o1.name == o2.name);
  }

}
