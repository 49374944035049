import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';
import { WINDOW } from '../window';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private apiUrl: string;

  user: User;
  constructor(
    @Inject(WINDOW) private window: Window,
    protected httpClient: HttpClient) {
    this.apiUrl = `${environment.protocol}${this.window.location.hostname}:${environment.port}${environment.apiUrl}`;
    if (!this.user) this.user = this.getUser();
  }

  getUser(): User {
    return JSON.parse(localStorage.getItem('user'));
  }

  saveUser(u: User) {
    this.user = u;
    localStorage.setItem('user', JSON.stringify(u));
  }

  isAdmin(): boolean {
    return this.user.roles.find(x => x.name === 'Admin') !== undefined;
  }

  isSupervisor(): boolean {
    return this.user.roles.find(x => x.name === 'Supervisor') !== undefined;
  }

  isCashier(): boolean {
    return this.user.roles.find(x => x.name === 'Cashier') !== undefined;
  }

  getAllUsers(): Observable<User[]> {
    return this.httpClient.get<User[]>(`${this.apiUrl}/users`);
  }

  getUserById(id: number): Observable<User> {
    return this.httpClient.get<User>(`${this.apiUrl}/users/${id}`);
  }

  createUser(user: User) {
    return this.httpClient.post<User>(`${this.apiUrl}/users`, user);
  }

  updateUser(id: number, user: User) {
    return this.httpClient.put<User>(`${this.apiUrl}/users/${id}`, user);
  }

  deleteUser(id: number) {
    return this.httpClient.delete<User>(`${this.apiUrl}/users/${id}`);
  }

}
