import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { version } from 'package.json';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {

  isAdmin: boolean;
  showTerminalItem: boolean;
  name: string;
  sageVersion: string;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.name = this.userService.user.firstName;
    this.isAdmin = this.userService.isAdmin() || this.userService.isSupervisor();
    if (this.router.url.includes('/admin')) this.isAdmin = false;
    this.router.url.includes('/terminal') ? this.showTerminalItem = false : this.showTerminalItem = true;
    this.sageVersion = version;
  }

  signout() {
    this.authService.signOut();
  }

  adminPanel() {
    if (this.userService.isAdmin()) this.router.navigate(['/admin/dashboard']);
    else this.router.navigate(['/admin/products']);
  }

  naviateTo(route: string) {
    this.router.navigate([route]);
  }

}
