<!-- Title and invoice number -->
<div fxLayout="column">
    <h2 mat-dialog-title>{{title}}</h2>

    <h4>Invoice#: {{invoiceDetails.invoiceNumber}}
        <span *ngIf="customer">, Customer: {{customer.firstName}} {{customer.lastName}}</span>
    </h4>
</div>

<!-- Reason for return -->
<div>
    <mat-form-field>
        <input #input fxFlex matInput placeholder="Reason for return" [(ngModel)]="returnReason" required>
    </mat-form-field>
</div>

<div fxlayout="row" fxLayoutAlign="stretch center">
    <button fxLayoutAlign="start center" mat-button color="primary"
        (click)="selectAllToggle()">{{selectAllText}}</button>
    <div fxLayout="row" fxFlex fxLayoutAlign="end center">
        <b> Total Refund Amount: {{totalRefundAmount | sageCurrency}}</b>
    </div>
</div>


<!-- Dialog content beings -->
<mat-dialog-content fxLayout="column" cdk-scrollable>

    <mat-selection-list #purchasedItems [(ngModel)]="selectedItems" (ngModelChange)="onNgModelChange($event)">
        <mat-list-option *ngFor="let item of transactionDetails.purchasedItems" [value]="item" [disableRipple]="true">
            <div fxLayout="row">
                <div fxFlex="10">
                    <input matInput value="{{item.quantity}}" min="0" max="{{item.quantity}}" type="number"
                        (change)="validateQuantity($event, item)" (click)="inputClicked($event)">
                </div>
                <div class="spacer"></div>
                <div fxFlex="60">
                    {{item.product.name}}
                </div>
                <div fxlayout="row">
                    <small class="refund">
                        {{calcItemRefund(item) | sageCurrency}}
                    </small>

                </div>
            </div>

        </mat-list-option>
    </mat-selection-list>

</mat-dialog-content>
<mat-divider></mat-divider><small>A Credit Note can only be issued once</small>




<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">

    <button mat-button color="warn" (click)="close()">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="refundDisabled" (click)="refund()">Refund</button>
</mat-dialog-actions>