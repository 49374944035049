import {
    HttpEvent,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
    HttpInterceptor
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IsLoadingService } from '@service-work/is-loading';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, retry, timeout } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { SnackbarService } from '../services/snackbar.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private isLoadingService: IsLoadingService,
        private authService: AuthService) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        this.isLoadingService.add();
        return next.handle(request)
            .pipe(
                timeout(20000),
                catchError((error: HttpErrorResponse) => {

                    if (error.error instanceof ErrorEvent) {
                        // client-side error
                        console.log('Client Side Error:', error);
                    } else {
                        // server-side error
                        console.log('Server Side Error:', error);
                    }
                    if (error.status == 401) {
                        console.log('Session Expired, please login again');
                        this.authService.signOut();
                        this.router.navigateByUrl('');
                    }
                    return throwError(error);
                })
                ,
                finalize(() => {
                    this.isLoadingService.remove();
                })
            )
    }
}