import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sageCurrency'
})
export class SageCurrencyPipe implements PipeTransform {

  transform(value: number): string {
    // return String(value)
    //   .split('.')
    //   .map(function (d, i) { return i ? d.substr(0, 2) : d; })
    //   .join('.');

    return 'K' + formatNumber(value, 'en-US', '1.2-2');

  }

}
