<div class="terminal-container">




    <div class="container-fluid selection-container">



        <div class="row">

            <div class="col p-0 m-0">
                <!--Toolbar -->

                <!-- Looading Bar -->
                <div class="loading">
                    <mat-progress-bar *ngIf="isLoading | async" mode="indeterminate" color="warn"></mat-progress-bar>
                </div>
                <!-- Search Bar -->
                <div class="row m-0">
                    <div class="col m-0 p-0">
                        <div class="search-items d-flex align-items-center shadow">

                            <app-user-menu class="pl-md-2 ">
                            </app-user-menu>

                            <mat-divider [vertical]="true" style="height:30px"></mat-divider>

                            <div class="d-flex justify-content-center align-items align-items-center w-100">
                                <mat-icon fxLayoutAlign>search</mat-icon>
                                <mat-form-field class="search-field">
                                    <mat-label>Search</mat-label>
                                    <input matInput [value]="textFilter" #input>
                                </mat-form-field>
                                <mat-paginator hidePageSize [length]="resultsLength">
                                </mat-paginator>
                            </div>



                        </div>

                    </div>
                </div>
                <div class="row m-0">
                    <!-- Category Selection-->
                    <div class="col-1 m-0 p-2 bg-primary-normal categories justify-content-center">
                        <div class="cat-card shadow-sm bg-primary-light"
                            [ngClass]="{'bg-accent-light':categoryFilter===''}" matRipple
                            (click)="selectedCategory(null)">
                            <h4>All</h4>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="cat-card shadow-sm bg-primary-light" *ngFor="let category of categories"
                            [ngClass]="{'bg-accent-light':categoryFilter===category.name}" matRipple
                            (click)="selectedCategory(category)">
                            <h4>{{category.name}}</h4>
                        </div>
                    </div>


                    <!-- Products List -->

                    <div class="col m-0 p-md-0 products d-flex flex-wrap justify-content-center"
                        (swipeLeft)="nextPage()" (swipeRight)="previousPage()" #productList>

                        <div class="product-card mat-elevation-z2 m-lg-2" *ngFor="let product of filteredProducts"
                            matRipple (click)="addItem(product)">
                            <div class="product-content">
                                {{ product.name }}
                            </div>

                            <div class="product-footer">
                                {{product.price + priceVATInclusive(product) | sageCurrency}}
                            </div>

                        </div>
                    </div>
                </div>

            </div>


            <!-- Register -->
            <div class="col-md-5 col-xl-3 register-container terminalmode-{{terminalMode}}">
                <!-- Modes -->
                <div class="row  mode-panel">
                    <div class="col d-inline d-flex p-1 justify-content-center align-items-center">
                        <small class="mode p-0" [matMenuTriggerFor]="menu">Mode</small>

                        <!-- <mat-icon [matMenuTriggerFor]="menu">settings</mat-icon> -->
                        <!-- <div class="justify-content-start">
                            <button mat-icon-button (dblclick)="menuTrigger.openMenu()">
                                <mat-icon>settings</mat-icon>
                            </button>
                        </div> -->
                        <mat-divider class="ml-3" [vertical]="true" style="height:40px"></mat-divider>
                        <div class="w-100 text-center">

                            {{terminalModeLabel}}
                        </div>


                        <mat-menu #menu="matMenu">
                            <div fxLayoutAlign="center center">
                                <small>Change Terminal Mode</small>
                            </div>
                            <mat-divider></mat-divider>
                            <button mat-menu-item (click)="setTerminalMode('normal')">
                                <mat-icon *ngIf="terminalMode==='normal'; else selectedMode">radio_button_checked
                                </mat-icon>
                                <span>Normal</span>
                            </button>
                            <button mat-menu-item (click)="setTerminalMode('privilege')">
                                <mat-icon *ngIf="terminalMode==='privilege'; else selectedMode">radio_button_checked
                                </mat-icon>
                                <span>Privileged Persons</span>
                            </button>
                            <button mat-menu-item (click)="setTerminalMode('export')">
                                <mat-icon *ngIf="terminalMode==='export'; else selectedMode">radio_button_checked
                                </mat-icon>
                                <span>Export</span>
                            </button>
                        </mat-menu>
                        <ng-template #selectedMode>
                            <mat-icon>radio_button_unchecked</mat-icon>
                        </ng-template>
                    </div>
                </div>



                <!-- Add Customer, Product or Clear all Items-->
                <div class="row">
                    <div class="col p-0 m-0">
                        <button mat-button color="primary" (click)="selectCustomer()">
                            <mat-icon>person_add</mat-icon>
                            Customer
                        </button>
                    </div>
                    <div class="col p-0 m-0">
                        <button mat-button color="primary" (click)="newProduct()">
                            <mat-icon>add</mat-icon>
                            Product
                        </button>
                    </div>

                    <div class="col p-0 m-0">
                        <button mat-button color="warn" (click)="clearAll()" [disabled]="purchasedItems.length < 1">
                            <mat-icon>delete</mat-icon>
                            <!-- Clear All -->Clear
                        </button>
                    </div>
                </div>
                <mat-divider></mat-divider>

                <!-- Register Items -->
                <div class="row">
                    <div class="col register-items" #registerItems>
                        <!-- Debit note info panel -->
                        <div *ngIf="debitNoteMode">
                            <div class="row">
                                <div class="col justify-content-center d-flex">
                                    <button mat-button color="warn" (click)="setDebitNote(false)">
                                        <mat-icon>remove_circle</mat-icon>
                                        Debit Note for Invoice: {{terminalParameters.invoiceNumber}}
                                    </button>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col justify-content-start">
                                    <mat-form-field class="w-100">
                                        <input matInput placeholder="Debit Note Memo" #debitNoteMemo>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>


                        <!-- Show selected customer -->
                        <div *ngIf="selectedCustomer" fxLayout="row">
                            <div fxFlex="70" fxLayoutAlign="start center">
                                Customer: <span style="width:5px"></span> <b>{{selectedCustomer.firstName}}
                                    {{selectedCustomer.lastName}}
                                </b>
                            </div>
                            <div fxFlex fxLayoutAlign="end center">
                                <button *ngIf="terminalMode!=='privilege'" fxLayoutAlign="end center" mat-button
                                    color="warn" (click)="removeCustomer()">
                                    <mat-icon>delete</mat-icon>
                                </button>
                                <span *ngIf="terminalMode==='privilege'"><small>LPO:
                                        {{selectedCustomer.lpo}}</small></span>
                            </div>
                        </div>
                        <!-- Products bought start here -->
                        <div *ngIf="purchasedItems.length > 0">

                            <div class="register-title-header">Purchased Items</div>
                            <div *ngFor="let item of purchasedItems" class="product" fxLayoutAlign="center center">
                                <div fxLayout="row" fxFlex="60" fxLayoutAlign="start center">
                                    {{ item.product.name }}
                                </div>

                                <div fxFlex="10" fxLayoutAlign="center center">
                                    <div>x {{ item.quantity }}</div>
                                </div>
                                <div fxFlex="20" fxLayoutAlign="end center">
                                    {{ (item.product.price + priceVATInclusive(item.product,true))* item.quantity |
                                    sageCurrency }}
                                </div>
                                <div fxFlex="10" fxLayoutAlign="center center">
                                    <mat-icon color="primary" (click)="editPurchasedItem(item)">edit</mat-icon>
                                </div>
                            </div>
                            <br />
                            <div class="register-title-header">Taxes</div>
                            <div *ngFor="let tax of taxList">
                                <div fxLayout="row" fxFlex="70">
                                    {{ tax.name }}
                                </div>

                                <div fxFlex="20" fxLayoutAlign="end center">
                                    {{ tax.amount | sageCurrency }}
                                </div>
                                <div fxFlex="10" fxLayoutAlign="end center">

                                </div>
                            </div>
                        </div>





                    </div>

                </div>

                <!-- Checkout Button -->
                <div class="row m-0 p-0 d-flex pt-1 flex-grow-1 ">
                    <button fxLayoutAlign="center center p-0 m-0" mat-raised-button color="accent" class="charge"
                        (click)="completeTransaction()" disabled="{{ purchasedItems.length < 1 }}">
                        <!-- <mat-icon>send</mat-icon> -->
                        Checkout <span style="width:150px"></span>

                        {{ totalItems() | sageCurrency }}
                    </button>

                </div>

            </div>
        </div>
    </div>
</div>