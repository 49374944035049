import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PagedData } from "../models/pagedData";
import { Sales } from '../models/sales';
import { WINDOW } from '../window';

@Injectable({
  providedIn: 'root'
})
export class SalesService {

  private apiUrl: string;

  constructor(
    @Inject(WINDOW) private window: Window,
    protected httpClient: HttpClient) {
    this.apiUrl = `${environment.protocol}${this.window.location.hostname}:${environment.port}${environment.apiUrl}`;
  }

  getSales(page: number, size: number, sortName: string, sortDirection: string, totalType?: string, transactionType?: number, filteredText?: string): Observable<PagedData<Sales[]>> {
    if (sortDirection == '') {
      sortName = 'invoiceNumber';
      sortDirection = 'desc';
    }

    let url = `${this.apiUrl}/sales?page=${page}&size=${size}&sortName=${sortName}&sortDirection=${sortDirection}`;
    if (filteredText) {
      url += `&filterText=${filteredText}`;
    }
    if (totalType) {
      if (sortName === 'date') sortName = 'createdAt';
      url = `${this.apiUrl}/sales/totals?page=${page}&size=${size}&sortName=${sortName}&sortDirection=${sortDirection}&totalType=${totalType}`;
      if (transactionType >= 0) {
        url += '&transactionType=' + transactionType;
      }
    }


    return this.httpClient.get<PagedData<Sales[]>>(url);
  }

  createSale(sales: Sales) {
    return this.httpClient.post<Sales>(`${this.apiUrl}/sales`, sales);
  }
}
