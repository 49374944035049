import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss']
})
export class PrintComponent implements OnInit {

  printStyle: string;

  constructor() { }

  ngOnInit(): void {
    this.printStyle = localStorage.getItem("printStyle");
    if (this.printStyle == "" || this.printStyle === null) this.printStyle = "s80";
    console.log('print style:', this.printStyle);
  }

  setPrintStyle() {
    console.log('selection changed to ', this.printStyle);
    localStorage.setItem("printStyle", this.printStyle);
  }

}
