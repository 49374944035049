<div class="add-button">
  <button mat-raised-button color="accent" (click)="addItem()">
    <mat-icon>add</mat-icon>
    Add Category
  </button>
</div>

<div class="mt-2 mat-elevation-z8">
  <mat-table [dataSource]="dataSource" matSort>



    <ng-container matColumnDef="name">
      <mat-header-cell fxFlex="20" *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
      <mat-cell fxFlex="20" *matCellDef="let row" data-label="name"> {{row.name}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell fxFlex="40" *matHeaderCellDef mat-sort-header> Description </mat-header-cell>
      <mat-cell fxFlex="40" *matCellDef="let row" data-label="description"> {{row.description}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="taxCodes">
      <mat-header-cell fxFlex="20" *matHeaderCellDef mat-sort-header> Tax Codes </mat-header-cell>
      <mat-cell fxFlex="20" *matCellDef="let row" [style.color]="row.color" data-label="taxCodes">
        {{getTaxName(row.taxCodes)}} </mat-cell>
    </ng-container>

    <!-- actions -->
    <ng-container matColumnDef="actions">
      <mat-header-cell fxLayoutAlign="end center" *matHeaderCellDef>

      </mat-header-cell>
      <mat-cell fxLayoutAlign="end center" *matCellDef="let row; let i=index;">
        <button mat-icon-button color="primary" (click)="editItem(row)">
          <mat-icon aria-label="Edit">edit</mat-icon>
        </button>

        <button mat-icon-button color="basic" (click)="deleteItem(row)">
          <mat-icon aria-label="Delete">delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;">
    </mat-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
</div>