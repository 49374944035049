import { ElementRef } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { IsLoadingService } from '@service-work/is-loading';
import { fromEvent, merge, Observable, of } from 'rxjs';
import { startWith, switchMap, map, catchError, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { Sales } from 'src/app/models/sales';
import { SalesTransaction } from 'src/app/models/salesTransaction';
import { AuthService } from 'src/app/services/auth.service';
import { SalesService } from 'src/app/services/sales.service';
import { PrintReceiptComponent } from '../print-receipt/print-receipt.component';
import { RefundComponent } from '../refund/refund.component';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss']
})
export class SalesComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;

  @ViewChild('input') input: ElementRef;
  public salesTransaction;
  public isPrinting: boolean;
  resultsLength = 0;
  sales: Sales[] = [];
  displayedColumns: string[] = [];
  private dialogConfig: MatDialogConfig;

  isLoading: Observable<boolean>;
  filterValue = '';

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private salesService: SalesService,
    private isLoadingService: IsLoadingService) { }

  ngOnInit(): void {
    this.displayedColumns = [
      'createdAt',
      'invoiceNumber',
      'salesTotal',
      'productTotal',
      'taxTotal',
      // 'tendered',
      // 'changeDue',
      'refund',
      'actions'
    ];
    this.dialogConfig = new MatDialogConfig();
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.width = '30rem';
    this.dialogConfig.maxHeight = '85vh';
    this.isLoading = this.isLoadingService.isLoading$();
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          console.log(`pageIndex:${this.paginator.pageIndex}, size: ${this.paginator.pageSize}`);
          return this.salesService.getSales(this.paginator.pageIndex, this.paginator.pageSize,
            this.sort.active, this.sort.direction, this.filterValue);
        }),
        map(results => {
          this.resultsLength = results.totalItems;
          return results.data
        }),
        catchError((err) => {
          console.log('An error occurred', err);
          return of([]);
        })
      ).subscribe((results) => {
        this.sales = results;
        fromEvent(this.input.nativeElement, 'keyup')
          .pipe(
            debounceTime(350),
            distinctUntilChanged(),
            tap((event: KeyboardEvent) => {
              this.applyFilter(event);
            })

          )
          .subscribe();
      });
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.refreshData(true);
  }

  refreshData(resetPage?: boolean) {
    if (resetPage) this.paginator.pageIndex = 0;
    console.log('filter text:', this.filterValue);
    this.salesService.getSales(this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction, null, null, this.filterValue).subscribe((results) => {
      this.sales = results.data;
    },
      error => {
        console.log('error loading getSales');

      });
  }

  pageEvent(event) {
    console.log(`page event: length:${event.length}, pageIndex:${event.pageIndex}, pageSize:${event.pageSize}`);
    this.refreshData();
  }

  showReceipt(receiptNo: string) {
    const details = this.sales.find(x => x.invoiceNumber == receiptNo);
    let transaction: SalesTransaction = JSON.parse(details.transactionDetails);
    transaction.transactionType = details.transactionType;
    transaction.refundReason = details.refundReason;

    console.log('(showreceipt) transaction:', transaction);
    this.router.navigate(['print'], { state: { transaction: transaction, returnRoute: this.router.url, copyInvoice: true } });
  }

  issueRefund(data: Sales) {
    this.dialogConfig.data = { saleDetails: data };
    const dialogRef = this.dialog.open(RefundComponent, this.dialogConfig);
  }

  debitNote(data: Sales) {
    this.router.navigate(['terminal'], { state: { data: data } });
  }


}
