<div fxLayout="row">
    <div fxLayoutAlign="center center">
        <span [matMenuTriggerFor]="menu">
            <!-- <small>{{name}}</small> -->
            Menu
        </span>
        <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>list</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <!-- <a mat-button routerLink="/terminal" routerLinkActive="router-link-active">
                <mat-icon>point_of_sale</mat-icon>
                Sales Terminal
            </a>
            <a mat-button routerLink="/sales" routerLinkActive="router-link-active">
                <mat-icon>show_chart</mat-icon>
                Invoice History
            </a> -->
            <span mat-menu-item>
                <mat-icon>person</mat-icon>
                <small>{{name}}</small>
            </span>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="naviateTo('/terminal')">
                <mat-icon>point_of_sale</mat-icon>
                <span>Sales Terminal</span>
            </button>
            <button mat-menu-item (click)="naviateTo('/sales')">
                <mat-icon>show_chart</mat-icon>
                <span>Invoice History</span>
            </button>

            <mat-divider></mat-divider>
            <button mat-menu-item *ngIf=" this.isAdmin" (click)="adminPanel()">
                <mat-icon>admin_panel_settings</mat-icon>
                <span>Admin Panel</span>
            </button>

            <button mat-menu-item (click)="signout()">
                <mat-icon>exit_to_app</mat-icon>
                <span>Logout</span>
            </button>
            <div fxLayoutAlign="center center" class="versionInfo">
                <small>Version: {{sageVersion}}</small>
            </div>
        </mat-menu>

    </div>

</div>