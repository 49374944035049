<h2 mat-dialog-title>{{title}}</h2>

<mat-dialog-content fxLayout="column">

  <p>
    {{description}}
  </p>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-button color="warn" (click)="cancel()">{{cancelLabel}}</button>
  <button mat-raised-button color="warn" (click)="ok()">{{okLabel}}</button>
</mat-dialog-actions>