<h2 mat-dialog-title>{{title}}</h2>

<!-- <mat-dialog-content fxLayout="column" [formGroup]="form"> -->
<div fxLayout="column" [formGroup]="form">
    <mat-error>
        {{formError}}
    </mat-error>
    <mat-form-field>
        <input matInput placeholder="First Name" formControlName="firstName">
        <mat-error *ngFor="let errorMessage of form.controls.firstName['errorMessages']">
            {{errorMessage}}
        </mat-error>
    </mat-form-field>

    <mat-form-field>
        <input matInput placeholder="Last Name" formControlName="lastName">
        <mat-error *ngFor="let errorMessage of form.controls.lastName['errorMessages']">
            {{errorMessage}}
        </mat-error>
    </mat-form-field>

    <mat-form-field>
        <input matInput placeholder="Registered Company Name" formControlName="companyName">
        <mat-error *ngFor="let errorMessage of form.controls.companyName['errorMessages']">
            {{errorMessage}}
        </mat-error>
    </mat-form-field>

    <mat-form-field>
        <input matInput placeholder="Telephone" formControlName="telephone">
        <mat-error *ngFor="let errorMessage of form.controls.telephone['errorMessages']">
            {{errorMessage}}
        </mat-error>
    </mat-form-field>



    <mat-form-field>
        <input matInput placeholder="Email" formControlName="email">
        <mat-error *ngFor="let errorMessage of form.controls.email['errorMessages']">
            {{errorMessage}}
        </mat-error>
    </mat-form-field>

    <mat-form-field>
        <input matInput placeholder="TPIN" formControlName="TPIN" required>
        <mat-error *ngFor="let errorMessage of form.controls.TPIN['errorMessages']">
            {{errorMessage}}
        </mat-error>
    </mat-form-field>

    <mat-form-field>
        <input matInput placeholder="Tax Account Name" formControlName="taxAccountName">
        <mat-error *ngFor="let errorMessage of form.controls.taxAccountName['errorMessages']">
            {{errorMessage}}
        </mat-error>
    </mat-form-field>

</div>
<!-- </mat-dialog-content> -->

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-button color="warn" (click)="close()">Cancel</button>
    <button mat-raised-button color="primary" (click)="save()">Save</button>
</mat-dialog-actions>