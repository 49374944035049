<div fxLayout="column" fxLayoutAlign="center center">
    <h2 mat-dialog-title>Complete Transaction </h2>
</div>


<!-- Total and Change Due -->
<div fxLayout="column" fxLayoutAlign="center center">
    <div fxLayoutAlign="center center">
        <b>Total Amount: {{totalAmount | sageCurrency}}</b>
    </div>
    <div fxLayoutAlign="center center" class="warn">
        <b>Change Due: {{ ((cashReceived?cashReceived:totalAmount) - totalAmount) | sageCurrency}}</b>
    </div>

</div>
<br>
<mat-dialog-content fxLayout="column">


    <mat-form-field>
        <input [disabled]="inputDisabled" matInput placeholder="Tendered Amount" type="number" #tenderedAmount
            (keyup)="updateChangeReceived($event)" [value]="cashReceived">
    </mat-form-field>


    <div fxLayout="column" fxLayoutAlign="center center">
        <small fxLayoutAlign="center center">Payment Mode</small>
        <mat-button-toggle-group name="paymentMode" value="0">
            <mat-button-toggle value=0 (click)="setPaymentMode(0)">Cash</mat-button-toggle>
            <mat-button-toggle value=1 (click)="setPaymentMode(1)">Card</mat-button-toggle>
            <mat-button-toggle value=2 (click)="setPaymentMode(2)">Cheque</mat-button-toggle>
            <mat-button-toggle value=3 (click)="setPaymentMode(3)">Electronic Transfer</mat-button-toggle>
            <mat-button-toggle value=4 (click)="setPaymentMode(4)">Credit Sale</mat-button-toggle>
        </mat-button-toggle-group>
        <div class="w-100" *ngIf="creditSale===true">
            <div *ngIf="!selectedCustomer" class="text-center customer-warning mt-2">
                * Please add a customer to this transaction
            </div>
            <div *ngIf="selectedCustomer" class="text-center mt-2">
                Selected Customer: <b> {{selectedCustomer?.firstName}} {{selectedCustomer?.lastName}} </b>
            </div>

        </div>
    </div>




</mat-dialog-content>

<br>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-button fxLayoutAlign="start center" color="warn" (click)="cancel()">Cancel</button>
    <button mat-raised-button fxLayoutAlign="center center" color="primary" (click)="ok()"
        [disabled]="transactionDisabled">Complete
        Transaction</button>
</mat-dialog-actions>