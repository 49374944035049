<div *ngIf="success">
    <div fxLayout="column" fxLayoutAlign="center center">
        <h2 mat-dialog-title>Success</h2>
    </div>

    <mat-dialog-content fxLayout="column">
        <!-- Total and Change Due -->
        <div *ngIf="!refund" fxFlex="30" fxLayoutAlign="center center" class="warn">
            <b>Change Due: {{changeDue | sageCurrency}}</b>
        </div>
        <div *ngIf="refund" fxFlex="30" fxLayoutAlign="center center" class="warn">
            <b>Total Refund: {{changeDue | sageCurrency}}</b>
        </div>

    </mat-dialog-content>
</div>

<div *ngIf="!success">
    <div fxLayout="column" fxLayoutAlign="center center">
        <h2 class="mat-error" mat-dialog-title>Error</h2>
    </div>

    <mat-dialog-content fxLayout="column">

        <div fxLayout="row">
            <div fxFlex="30" fxLayoutAlign="start center">
                <b>Error Code:</b>
            </div>
            <div fxFlex fxLayoutAlign="start center" class="mat-error">
                {{errorCode}}
            </div>
        </div>

        <div fxLayout="row">
            <div fxFlex="30" fxLayoutAlign="start center">
                <b>Description:</b>
            </div>
            <div fxFlex fxLayoutAlign="start center" class="mat-error">
                {{errorDescription}}
            </div>
        </div>

    </mat-dialog-content>
</div>

<br>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="stretch center">
    <button fxFlex mat-raised-button fxLayoutAlign="center center" color="primary"
        (click)="printReceipt()">{{buttonLabel}}
    </button>
</mat-dialog-actions>