import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Customer } from 'src/app/models/customer';
import { PagedData } from '../models/pagedData';
import { WINDOW } from '../window';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  private apiUrl: string;
  constructor(
    @Inject(WINDOW) private window: Window,
    protected httpClient: HttpClient) {
    this.apiUrl = `${environment.protocol}${this.window.location.hostname}:${environment.port}${environment.apiUrl}`;
  }

  getCustomers(page: number, size: number, sortName: string, sortDirection: string, filteredText?: string): Observable<PagedData<Customer[]>> {
    if (sortDirection == '') {
      sortName = 'firstName';
      sortDirection = 'asc';
    }
    let url = `${this.apiUrl}/customers/pagination?page=${page}&size=${size}&sortName=${sortName}&sortDirection=${sortDirection}`;
    if (filteredText) {
      url += `&filterText=${filteredText}`;
    }
    return this.httpClient.get<PagedData<Customer[]>>(url);
  }

  getCustomerById(id: number): Observable<Customer> {
    return this.httpClient.get<Customer>(`${this.apiUrl}/customers/${id}`);
  }

  updateCustomer(id: number, customer: Customer) {
    return this.httpClient.put<Customer>(`${this.apiUrl}/customers/${id}`, customer);
  }

  createCustomer(customer: Customer) {
    return this.httpClient.post<Customer>(`${this.apiUrl}/customers`, customer);
  }

  deleteCustomer(id: number) {
    return this.httpClient.delete<Customer>(`${this.apiUrl}/customers/${id}`);
  }

}
