import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-transaction-result',
  templateUrl: './transaction-result.component.html',
  styleUrls: ['./transaction-result.component.scss']
})
export class TransactionResultComponent implements OnInit {
  changeDue: number;
  success: boolean;
  buttonLabel: string;
  errorCode: string;
  errorDescription: string;
  refund: boolean;
  constructor(
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data

  ) {

    this.success = data.success;
    data.refund ? this.refund = true : this.refund = false;
    if (data.success) {
      this.changeDue = data.changeDue;
      this.buttonLabel = "Print Receipt";
    }
    else {
      this.buttonLabel = "Close";
      if (!data.errorCode) {
        this.errorCode = '0';
        this.errorDescription = 'Unknown Error';
      }
      else {
        console.log('data error:', data.errorCode)
        this.errorCode = data.errorCode;
        this.errorDescription = data.errorDescription;
      }

    }
  }

  ngOnInit(): void {

  }

  printReceipt() {
    this.dialogRef.close();
  }

}
