import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-custom-print',
  templateUrl: './custom-print.component.html',
  styleUrls: ['./custom-print.component.scss']
})
export class CustomPrintComponent implements OnInit {

  public salesTransaction;
  private transaction;


  constructor(
    private router: Router
  ) {
    this.transaction = this.router.getCurrentNavigation().extras.state.transaction;
  }

  ngOnInit(): void {
    console.log(`transaction: ${this.transaction}`);
    setTimeout(() => {
      this.salesTransaction = this.transaction;
      console.log(`should be printing: ${this.salesTransaction}`);

    }, 100);
  }

}
