<h2 mat-dialog-title>{{title}}</h2>

<div fxLayout="column" [formGroup]="form">

  <mat-form-field>
    <input matInput placeholder="Name" formControlName="name" required>
    <mat-error *ngFor="let errorMessage of form.controls.name['errorMessages']">
      {{errorMessage}}
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <input matInput placeholder="Description" formControlName="description">
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="Tax Code" formControlName="code">
  </mat-form-field>

  <mat-form-field>
    <input matInput type="number" placeholder="Amount" formControlName="amount" required>
    <mat-error *ngFor="let errorMessage of form.controls.amount['errorMessages']">
      {{errorMessage}}
    </mat-error>
  </mat-form-field>
</div>


<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-button color="warn" (click)="close()">Cancel</button>
  <button mat-raised-button color="primary" (click)="save()">Save</button>
</mat-dialog-actions>