import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SigninComponent } from './components/signin/signin.component';
import { CategoriesComponent } from './components/admin/categories/categories.component';
import { ProductsComponent } from './components/admin/products/products.component';
import { TaxesComponent } from './components/admin/taxes/taxes.component';
import { AdminPanelComponent } from './components/admin/admin-panel/admin-panel.component';
import { SalesComponent } from './components/sales/sales.component';
import { SalesTerminalComponent } from './components/sales-terminal/sales-terminal.component';
import { EsdComponent } from './components/admin/esd/esd.component';
import { TerminalComponent } from './components/terminal/terminal.component';
import { UsersComponent } from './components/admin/users/users.component';
import { CustomersComponent } from './components/admin/customers/customers.component';
import { AuthGuard } from '../app/auth/auth.guard';
import { AdminGuard } from './auth/admin.guard';
import { SupervisorGuard } from './auth/supervisor.guard';
import { CustomPrintComponent } from './components/custom-print/custom-print.component';
import { DashboardComponent } from './components/admin/dashboard/dashboard.component';
import { PrintComponent } from './components/admin/print/print.component';

const routes: Routes = [
  {
    path: '',
    component: SigninComponent
  },
  {
    path: 'terminal',
    component: TerminalComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'print',
    component: CustomPrintComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'sales',
    component: SalesComponent,
    canActivate: [AuthGuard]
  },

  {
    path: 'admin', component: AdminPanelComponent,
    canActivate: [AuthGuard, SupervisorGuard],
    children: [

      { path: 'dashboard', component: DashboardComponent },
      { path: 'users', component: UsersComponent },
      { path: 'categories', component: CategoriesComponent },
      { path: 'products', component: ProductsComponent },
      { path: 'taxes', component: TaxesComponent },
      { path: 'customers', component: CustomersComponent },
      { path: 'esd', component: EsdComponent, canActivate: [AdminGuard] },
      { path: 'print', component: PrintComponent }
    ]
  }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
