import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { SageCurrencyPipe } from '../../../pipes/sage-currency.pipe';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-complete-sale',
  templateUrl: './complete-sale.component.html',
  styleUrls: ['./complete-sale.component.scss']
})
export class CompleteSaleComponent {
  totalAmount = 0;
  cashReceived: number;
  creditSale: boolean;
  changeDue: number = 0;
  transactionDisabled = false;
  inputDisabled = false;
  paymentMode = 0;
  selectedCustomer;
  completeTransaction: BehaviorSubject<{ cashReceived: number, paymentMode: number }>;
  @ViewChild('tenderedAmount') tenderedAmount: ElementRef;



  constructor(
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.totalAmount = data.totalAmount;
    this.cashReceived = this.totalAmount;
    this.creditSale = false;
    this.selectedCustomer = data.selectedCustomer;
    this.totalAmount = parseFloat(this.totalAmount.toFixed(2)); //trimming decimal to 2 places
    this.completeTransaction = new BehaviorSubject<{ cashReceived: number, paymentMode: number }>(null);
  }

  cancel() {
    this.dialogRef.close();
  }

  ok() {
    this.completeTransaction.next({ cashReceived: this.cashReceived, paymentMode: this.paymentMode });
    this.transactionDisabled = true;
    this.inputDisabled = true;
  }

  updateChangeReceived(event: any) {
    this.cashReceived = event.target.value;
    if (this.cashReceived - this.totalAmount < 0) {
      this.transactionDisabled = true;
    }
    else {

      this.transactionDisabled = false;
      if (event.key === "Enter") {
        this.ok();
      }
    }
  }

  setPaymentMode(mode: number) {
    this.paymentMode = mode;
    this.creditSale = false;
    if (mode >= 0 && mode < 4) {
      this.transactionDisabled = false;
      this.cashReceived = this.totalAmount;
      this.tenderedAmount.nativeElement.value = this.totalAmount;
    }
    else if (mode === 4) {
      this.creditSale = true;
      this.transactionDisabled = this.selectedCustomer ? false : true;
      this.cashReceived = this.totalAmount;
      this.tenderedAmount.nativeElement.value = this.totalAmount;
    }

    // else {
    //   this.transactionDisabled = false;
    //   this.cashReceived = 0;
    //   this.tenderedAmount.nativeElement.value = '';
    //   this.tenderedAmount.nativeElement.focus();
    // }
  }

}
