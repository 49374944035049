import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Tax } from 'src/app/models/inventory';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReactiveFormConfig, RxFormBuilder, RxwebValidators } from '@rxweb/reactive-form-validators';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-edit-taxes-dialog',
  templateUrl: './edit-taxes-dialog.component.html',
  styleUrls: ['./edit-taxes-dialog.component.scss']
})
export class EditTaxesDialogComponent implements OnInit {
  title: string;
  form: FormGroup;
  taxData: Tax;
  saveData: BehaviorSubject<Tax>;

  constructor(
    private fb: RxFormBuilder,
    private dialogRef: MatDialogRef<EditTaxesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.saveData = new BehaviorSubject<Tax>(null);
    if (data.id) {
      this.taxData = data;
    }
    else {
      this.title = "Add Tax";
      this.taxData = <Tax>{};
    }
  }

  ngOnInit(): void {
    ReactiveFormConfig.set({
      "validationMessage": {
        "required": "This field is required",
        "numeric": "Please enter a numeric value"
      }
    });
    this.form = this.fb.group({
      name: [this.taxData.name, RxwebValidators.required()],
      description: [this.taxData.description],
      code: [this.taxData.code],
      amount: [this.taxData.amount, [RxwebValidators.required(), RxwebValidators.numeric({ allowDecimal: true, isFormat: true })]]
    });
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    if (this.form.valid) {
      let t = <Tax>{};
      t.name = this.form.controls['name'].value;
      t.description = this.form.controls['description'].value;
      t.code = this.form.controls['code'].value;
      t.amount = this.form.controls['amount'].value;

      this.saveData.next(t);
    }
    else {
      this.form.markAllAsTouched();
    }

  }

}


