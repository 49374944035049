<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">

    <mat-nav-list>
      <div>
        <a mat-button routerLink="/terminal" routerLinkActive="router-link-active">
          <mat-icon>arrow_back</mat-icon>
          Back to Sales Terminal
        </a>
      </div>
      <br>
      <mat-divider></mat-divider>
      <div *ngFor="let m of menuItems">
        <a *ngIf="m.visible" mat-list-item [routerLink]="'/'+m.route" class="mat-menu-item"
          routerLinkActive="router-link-active">
          <mat-icon>{{m.icon}}</mat-icon>
          <span>{{m.name | titlecase}}</span>
        </a>
      </div>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>

    <app-header-toolbar [drawer]="drawer"></app-header-toolbar>
    <!-- Loading Bar -->
    <div class="loading">
      <mat-progress-bar *ngIf="isLoading | async" mode="indeterminate" color="warn"></mat-progress-bar>
    </div>
    <!-- Add Content Here -->
    <div class="sidenav-content">
      <router-outlet></router-outlet>
    </div>

  </mat-sidenav-content>
</mat-sidenav-container>