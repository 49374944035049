import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { EditCustomerDialogComponent } from '../dialogs/edit-customer-dialog/edit-customer-dialog.component';
import { fromEvent, merge, of } from 'rxjs';
import { startWith, switchMap, map, catchError, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { Customer } from 'src/app/models/customer';
import { CustomerService } from 'src/app/services/customer.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;

  @ViewChild('input') input: ElementRef;

  resultsLength = 0;
  filterValue = '';
  customers: Customer[] = [];
  displayedColumns: string[] = [];
  private dialogConfig: MatDialogConfig;


  constructor(
    private customerService: CustomerService,
    private dialog: MatDialog,
    private snackbarService: SnackbarService
  ) { }

  ngOnInit(): void {
    this.displayedColumns = [
      'firstName',
      'lastName',
      'companyName',
      // 'address',
      'email',
      'telephone',
      'TPIN',
      'taxAccountName',
      'actions'
    ];

    this.dialogConfig = new MatDialogConfig();
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.width = '30rem';
    this.dialogConfig.maxHeight = '85vh';
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.customerService.getCustomers(this.paginator.pageIndex, this.paginator.pageSize,
            this.sort.active, this.sort.direction, this.filterValue);
          ;
        }),
        map(results => {
          this.resultsLength = results.totalItems;
          return results.data
        }),
        catchError((err) => {
          console.log('An error occurred', err);
          return of([]);
        })
      ).subscribe((results) => this.customers = results);

    // wire up search input to debounce time
    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(350),
        distinctUntilChanged(),
        tap((event: KeyboardEvent) => {
          this.applyFilter(event);
        })

      )
      .subscribe();
  }

  addItem() {
    this.dialogConfig.data = { customer: null };
    const dialogRef = this.dialog.open(
      EditCustomerDialogComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.customerService.createCustomer(data).subscribe(
          () => {
            this.snackbarService.showSuccessMessage('Customer added');
            this.refreshData();
          },
          (err) => {
            this.snackbarService.showErrorMessage('Error: could not add customer');
            //handle error here
          }
        );
      }
    });
  }

  editItem(item: Customer) {
    this.dialogConfig.data = { customer: item };;
    const dialogRef = this.dialog.open(
      EditCustomerDialogComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.customerService.updateCustomer(item.id, data).subscribe(
          () => {
            this.snackbarService.showSuccessMessage('Customer edited successfully');
            this.refreshData();
          },
          (err) => {
            this.snackbarService.showErrorMessage('Error: could not edit customer');
            //handle error here          
          }
        );
      }
    });
  }

  deleteItem(item: Customer) {

    this.dialogConfig.autoFocus = false;
    this.dialogConfig.data = {
      title: 'Delete Customer',
      description: `Are you sure you want to delete "${item.firstName} ${item.lastName}" ?`,
      okLabel: 'Delete',
      cancelLabel: 'Cancel',
    };
    const dialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.customerService.deleteCustomer(item.id).subscribe(
          () => {
            this.snackbarService.showSuccessMessage('Customer deleted');
            this.refreshData();
          },
          (err) => {
            this.snackbarService.showErrorMessage('Error: could not delete customer');
            // handle error here
          }
        );
      }
    });
  }

  refreshData(resetPage?: boolean) {
    if (resetPage) this.paginator.pageIndex = 0;
    this.customerService.getCustomers(this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction, this.filterValue).subscribe((results) => {
      this.customers = results.data;
      this.resultsLength = results.totalItems;
    });
  }

  pageEvent(event) {
    console.log(`page event: length:${event.length}, pageIndex:${event.pageIndex}, pageSize:${event.pageSize}`);
    if (event.sortChange) {
      console.log('sort changed');
    }
    this.refreshData();
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.refreshData(true);
  }

}
