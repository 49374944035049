import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ReactiveFormConfig, RxFormBuilder, RxwebValidators } from '@rxweb/reactive-form-validators';
import { ESD, ESDConfig } from 'src/app/models/esd';
import { EsdService } from 'src/app/services/esd.service';

@Component({
  selector: 'app-esd',
  templateUrl: './esd.component.html',
  styleUrls: ['./esd.component.scss']
})
export class EsdComponent implements OnInit {
  esdForm: FormGroup;

  esd: ESD;
  esdConfig: ESDConfig;
  esdConfigError: string = '';
  esdTime: string = '';
  taxInfoData: any;
  displayedColumns: string[] = [];

  constructor(
    private fb: RxFormBuilder,

    private esdService: EsdService
  ) { }

  ngOnInit(): void {
    ReactiveFormConfig.set({
      "validationMessage": {
        "required": "This field is required",
        "email": "Please enter a valid email address"
      }
    });

    this.esdForm = this.fb.group({
      PosSerialNumber: ['', RxwebValidators.required()],
      PosVendor: ['', RxwebValidators.required()],
      PosModel: ['', RxwebValidators.required()],
      PosSoftVersion: ['', RxwebValidators.required()],
      Port: ['', RxwebValidators.required()],
      IP: ['', RxwebValidators.required()]
    });

    this.displayedColumns = [
      'id',
      'label',
      'name',
      'rate',
      'effectiveDate',
      'expiredDate'
    ]

    this.connectToESD();

  }

  connectToESD() {
    this.esdService.getESDConfig().subscribe(results => {
      // this.isLoadingService.remove();
      this.esdConfig = results;
      this.esdForm.controls['PosSerialNumber'].setValue(this.esdConfig.PosSerialNumber);
      this.esdForm.controls['PosVendor'].setValue(this.esdConfig.PosVendor);
      this.esdForm.controls['PosModel'].setValue(this.esdConfig.PosModel);
      this.esdForm.controls['PosSoftVersion'].setValue(this.esdConfig.PosSoftVersion);
      this.esdForm.controls['Port'].setValue(this.esdConfig.Port);
      this.esdForm.controls['IP'].setValue(this.esdConfig.IP);

      console.log('esd:', results);
      this.esdConfigError = '';
      // this.isLoadingService.remove();

      // get ESD Status
      this.esdService.getESDStatus(true).subscribe(result => {
        console.log(`est return:`, result);
        this.esd = result;
        this.esdTime += this.esdService.getEsdDateTime(result.ESDTime);
      },
        error => {
          this.esdConfigError = 'Could not get ESD Status'
        });
    },
      error => {
        this.esdConfigError = 'Could not connect to ESD Device'
        console.log('no data present');
      });

  }

  saveESDConfiguration() {
    let esdConfig: ESDConfig = <ESDConfig>{};
    if (this.esdForm.valid) {
      esdConfig.PosSerialNumber = this.esdForm.controls['PosSerialNumber'].value;
      esdConfig.PosVendor = this.esdForm.controls['PosVendor'].value;
      esdConfig.PosModel = this.esdForm.controls['PosModel'].value;
      esdConfig.PosSoftVersion = this.esdForm.controls['PosSoftVersion'].value;
      esdConfig.Port = this.esdForm.controls['Port'].value;
      esdConfig.IP = this.esdForm.controls['IP'].value;

      this.esdService.updateESDConfig(esdConfig).subscribe(result => {
        this.esdService.getESDStatus(true).subscribe(esd => {
          this.esd = esd;
          this.esdConfigError = '';
        },
          error => {
            this.esdConfigError = 'Could not get ESD Status';
          })
      },
        error => {
          this.esdConfigError = 'Could not connect to ESD Device';

        });
    }
  }

}
