<h2 mat-dialog-title>{{title}}</h2>

<div fxLayout="column" [formGroup]="form">
    <mat-form-field>
        <input matInput placeholder="First Name" formControlName="firstName" [readonly]="changePassword" required>
        <mat-error *ngFor="let errorMessage of form.controls.firstName['errorMessages']">
            {{errorMessage}}
        </mat-error>
    </mat-form-field>

    <mat-form-field>
        <input matInput placeholder="Last Name" formControlName="lastName" [readonly]="changePassword" required>
        <mat-error *ngFor="let errorMessage of form.controls.lastName['errorMessages']">
            {{errorMessage}}
        </mat-error>
    </mat-form-field>

    <mat-form-field>
        <input matInput placeholder="Username" formControlName="username" [readonly]="changePassword" required>
        <mat-error *ngFor="let errorMessage of form.controls.username['errorMessages']">
            {{errorMessage}}
        </mat-error>
    </mat-form-field>

    <mat-form-field>
        <input matInput placeholder="Email" formControlName="email" [readonly]="changePassword" required>
        <mat-error *ngFor="let errorMessage of form.controls.email['errorMessages']">
            {{errorMessage}}
        </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="changePassword || !isEditing">
        <input matInput placeholder="Password" formControlName="password">
        <mat-error *ngFor="let errorMessage of form.controls.password['errorMessages']">
            {{errorMessage}}
        </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="!changePassword">
        <mat-select formControlName="roles" placeholder="Roles" [compareWith]="compareFunction" multiple>
            <mat-option *ngFor="let role of roleList" [value]="role">{{role.name}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-button color="warn" (click)="close()">Cancel</button>
    <button mat-raised-button color="primary" (click)="save()">Save</button>
</mat-dialog-actions>