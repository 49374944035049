import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RxFormBuilder, ReactiveFormConfig, RxwebValidators, NumericValueType } from '@rxweb/reactive-form-validators';
import { Customer } from 'src/app/models/customer';
import { CustomerService } from 'src/app/services/customer.service';
import { EditCategoryDialogComponent } from '../edit-category-dialog/edit-category-dialog.component';

@Component({
  selector: 'app-edit-customer-dialog',
  templateUrl: './edit-customer-dialog.component.html',
  styleUrls: ['./edit-customer-dialog.component.scss']
})
export class EditCustomerDialogComponent implements OnInit {
  title: string;
  formError: string;
  form: FormGroup;
  customerData: Customer;
  isEditing: boolean;

  constructor(
    private fb: RxFormBuilder,
    private dialogRef: MatDialogRef<EditCustomerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    if (data.customer) {
      this.customerData = data.customer;
      this.title = 'Edit Customer';
      this.isEditing = true;
    } else {
      this.title = ' Add Customer';
      this.customerData = <Customer>{};
    }

  }

  ngOnInit(): void {
    ReactiveFormConfig.set({
      "validationMessage": {
        "required": "This field is required",
        "email": "Please enter a valid email address",
        "numeric": "Must be a numeric value",
        "minLength": "Must be 10 digits",
        "maxLength": "Must be 10 digits"
      }
    });

    this.form = this.fb.group({
      firstName: [this.customerData.firstName],
      lastName: [this.customerData.lastName],
      companyName: [this.customerData.companyName],
      address: [this.customerData.address],
      telephone: [this.customerData.telephone],
      TPIN: [this.customerData.TPIN,
      [RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: false }),
      RxwebValidators.maxLength({ value: 10 }), RxwebValidators.minLength({ value: 10 }),
      RxwebValidators.required]],
      email: [this.customerData.email, RxwebValidators.email()],
      taxAccountName: [this.customerData.taxAccountName]
    });

  }

  close() {
    this.dialogRef.close();
  }


  save() {
    this.checkEmptyFields();
    if (this.form.valid) {
      this.customerData.firstName = this.form.controls['firstName'].value;
      this.customerData.lastName = this.form.controls['lastName'].value;
      this.customerData.companyName = this.form.controls['companyName'].value;
      this.customerData.address = this.form.controls['address'].value;
      this.customerData.telephone = this.form.controls['telephone'].value;
      this.customerData.email = this.form.controls['email'].value;
      this.customerData.TPIN = this.form.controls['TPIN'].value;
      this.customerData.taxAccountName = this.form.controls['taxAccountName'].value;
      this.dialogRef.close(this.customerData);
    }
    else {
      this.form.markAllAsTouched();
    }
  }

  private checkEmptyFields() {
    console.log('checking fields');
    console.log(`firstName value: ${this.form.controls['firstName'].value}`);
    if (!this.form.controls['firstName'].value &&
      !this.form.controls['lastName'].value &&
      !this.form.controls['companyName'].value) {
      this.form.setErrors({ 'null': true });
      this.formError = 'First Name, Last Name or Registered Company Name required';
    }
  }

}
