import { Component, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-header-toolbar',
  templateUrl: './header-toolbar.component.html',
  styleUrls: ['./header-toolbar.component.scss']
})
export class HeaderToolbarComponent implements OnInit {
  @Input() drawer: MatSidenav;


  constructor(
  ) { }

  ngOnInit(): void {
  }

  toggleDrawer() {
    this.drawer.toggle();
  }

}
