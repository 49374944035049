import { DatePipe } from '@angular/common';
import { RecursiveTemplateAstVisitor } from '@angular/compiler';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { startsWith, time } from '@rxweb/reactive-form-validators';
import { IsLoadingService } from '@service-work/is-loading';
import { forkJoin, fromEvent, merge, Observable, of, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, finalize, map, startWith, switchMap, tap } from 'rxjs/operators';
import { Customer } from 'src/app/models/customer';
import { ESD } from 'src/app/models/esd';
import { scanner } from 'src/environments/environment';
import { ESDSignedResponse, ESDSignInvoiceRequest, Item } from 'src/app/models/esdSigning';
import {
  Category,
  Product,
  PurchasedItem,
  Tax,
} from 'src/app/models/inventory';
import { Sales } from 'src/app/models/sales';
import { SalesTransaction } from 'src/app/models/salesTransaction';
import { CustomerService } from 'src/app/services/customer.service';
import { EsdService } from 'src/app/services/esd.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { SalesService } from 'src/app/services/sales.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UserService } from 'src/app/services/user.service';
import { EditCustomerDialogComponent } from '../admin/dialogs/edit-customer-dialog/edit-customer-dialog.component';
import { EditProductDialogComponent } from '../admin/dialogs/edit-product-dialog/edit-product-dialog.component';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';
import { EditPurchasedItemComponent } from '../dialogs/edit-purchased-item/edit-purchased-item.component';
import { SelectCustomerComponent } from '../shared/select-customer/select-customer.component';
import { CompleteSaleComponent } from './complete-sale/complete-sale.component';
import { TransactionResultComponent } from './transaction-result/transaction-result.component';
import * as esdHelpers from '../../ESDHelpers';
import * as taxHelpers from '../../taxHelpers';

const roundTo = function (num: number, places: number) {
  const factor = 10 ** places;
  return Math.round(num * factor) / factor;
};

@Component({
  selector: 'app-sales-terminal',
  templateUrl: './sales-terminal-refresh.component.html',
  styleUrls: ['./sales-terminal.component.scss'],
  host: {
    '(document:keyup)': 'handleKeyboardEvent($event)'
  }
})

export class SalesTerminalComponent implements OnInit {
  @ViewChild('registerItems') private registerItems: ElementRef;
  @ViewChild('productList') private productListContainer: ElementRef;
  @ViewChild('productContainer') private productContainer: ElementRef;
  @ViewChild('input') input: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('debitNoteMemo') debitNoteMemo: ElementRef;

  // Each square size
  private productElementSize_width = 200;
  private productElementSize_height = 58;
  @Input() terminalParameters: Sales;
  @Output() purchaseList = new EventEmitter<SalesTransaction>();

  resultsLength = 0;

  products: Product[] = [];
  categories: Category[] = [];
  taxes: Tax[] = [];
  filteredProducts: Product[] = [];
  purchasedItems: PurchasedItem[] = [];
  taxList: Tax[] = [];
  esdStatus: ESD;
  customProductId: number;
  terminalMode = 'normal';
  terminalModeLabel = 'Normal Mode';

  sortField = 'name';
  sortDirection = 'asc';
  loadOptions: {};
  categoryFilter: string = '';
  textFilter: string = '';
  selectedCustomer: Customer;
  debitNoteMode = false;
  barCodeValue = "";
  barCodeTime: number;



  isLoading: Observable<boolean>;

  @ViewChild('loadMore') loadMore: ElementRef<HTMLElement>;

  private dialogConfig: MatDialogConfig;
  private scanning: boolean;

  constructor(
    private isLoadingService: IsLoadingService,
    private inventoryService: InventoryService,
    private userService: UserService,
    private customerService: CustomerService,
    private esdService: EsdService,
    private salesService: SalesService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private snackbarService: SnackbarService
  ) {

  }

  ngOnInit(): void {
    if (this.terminalParameters) {
      this.setDebitNote(true);
    }
    this.isLoading = this.isLoadingService.isLoading$();
    forkJoin([

      this.inventoryService.getCategories(),
      this.inventoryService.getTaxes()
    ]).subscribe(([categories, taxes]) => {
      this.categories = categories;
      this.taxes = taxes;

    });
    this.esdService.getESDStatus(false).subscribe(res => {
      this.esdStatus = res;
    },
      err => {
        this.snackbarService.showLongErrorMessage('Error: could not connect to ESD');
      });
    this.dialogConfig = new MatDialogConfig();
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.width = '30rem';
    this.dialogConfig.maxHeight = '85vh';

    this.customProductId = 1.1;

    this.barCodeTime = new Date().getTime();
  }

  paginatorSetup(pageSize: number) {
    merge(this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.inventoryService.getProductsPaged(this.paginator.pageIndex, pageSize,
            this.sortField, this.sortDirection, this.textFilter, this.categoryFilter);
        }),
        map(results => {
          this.resultsLength = results.totalItems;
          return results.data;
        }),
        catchError((err) => {
          this.snackbarService.showLongErrorMessage('Error Loading Products');
          console.log('Error Loading Products', err);
          return of([]);
        })
      ).subscribe((results) => {

        this.filteredProducts = results;
        console.log(`paginator event, filteredProducts length: ${this.filteredProducts.length}`);
      });
  }

  ngAfterViewInit() {
    // this.startScrollMonitoring();
    // need to use this hack for loading the right number of products based on the container size
    setTimeout(() => {
      console.log('from timeout:');
      const h = this.productListContainer.nativeElement.offsetHeight;
      const w = this.productListContainer.nativeElement.offsetWidth - 20;
      const c = Math.floor(w / this.productElementSize_width);
      const r = Math.floor(h / this.productElementSize_height);

      const itemsToLoad = c * r; //Math.floor((h / this.productElementSize) * (w / this.productElementSize));
      this.paginator.pageSize = itemsToLoad;
      console.log(`container h:${h} w:${w} `);
      console.log(`should load about: ${itemsToLoad}, columns: ${c} and rows:${r}`);
      this.paginatorSetup(itemsToLoad);
    }, 200);




    // wire up search input to debounce time
    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(350),
        distinctUntilChanged(),
        tap((event: KeyboardEvent) => {
          this.applyFilter(event);
        })

      )
      .subscribe();


  }



  async getProducts(i: number, count: number, filteredText?: string, categoryName?: string) {
    this.inventoryService.getProductsPaged(i, count, this.sortField, this.sortDirection, filteredText, categoryName).subscribe(products => {
      console.log('getting products...');
      const newlist = this.filteredProducts.concat(products.data);
      filteredText ? this.filteredProducts = products.data : this.filteredProducts = newlist;
      this.resultsLength = products.totalItems;
    },
      error => {
        this.snackbarService.showLongErrorMessage('Error: could not retrieve product information');
      });
  }

  ngAfterViewChecked() {
    this.registerItems.nativeElement.scrollTop = this.registerItems.nativeElement.scrollHeight;
  }


  selectedCategory(category: Category) {
    this.categoryFilter = category ? category.name : '';
    this.filteredProducts = [];
    this.textFilter = '';
    this.paginator.pageIndex = 0;
    this.getProducts(0, this.paginator.pageSize, this.textFilter, this.categoryFilter);
  }

  addItem(p: Product, q?: number) {
    const addedItem = {
      product: p,
      quantity: 1,
    };

    if (q) addedItem.quantity = q;
    var item = this.purchasedItems.find((x) => x.product.id == p.id);
    if (item) item.quantity += 1;
    else this.purchasedItems.push(addedItem);
    console.log(`added items:`, this.purchasedItems);
    this.updateTaxList();
  }

  totalItems(): number {
    var total = 0;
    for (let i = 0; i < this.purchasedItems.length; i++) {
      total +=
        this.purchasedItems[i].product.price * this.purchasedItems[i].quantity;
    }
    for (let i = 0; i < this.taxList.length; i++) {
      total += this.taxList[i].amount;
    }
    return total;
  }

  editPurchasedItem(item: PurchasedItem) {
    this.dialogConfig.data = item;
    const dialogRef = this.dialog.open(
      EditPurchasedItemComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe((data) => {
      if (!data) return;

      if (data.quantity === 0) {
        this.purchasedItems = this.purchasedItems.filter(x => x.product.id !== data.product.id);
      }
      else { item = data; }
      this.updateTaxList();
    },
      (err) => {

      });
  }

  selectCustomer(customer?: Customer) {
    this.dialogConfig.data = { mode: this.terminalMode, customer: customer };
    const dialogRef = this.dialog.open(
      SelectCustomerComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe((data) => {
      console.log(`selected customer dialog closed`);
      if (data && data.id) {
        //selected customer
        console.log(`${data.firstName} selected from list, lpo:${data.lpo}`);
        this.selectedCustomer = data;
      }

      if (data === 'new') {
        // add new customer
        this.dialogConfig.data = { customer: null };
        const addCustomerRef = this.dialog.open(
          EditCustomerDialogComponent,
          this.dialogConfig
        );
        addCustomerRef.afterClosed().subscribe((customer) => {
          if (customer) {
            this.addAndSelectCustomer(customer);
          }

        })
      }

      if (!data) {
        if (this.terminalMode === 'privilege') {
          this.terminalMode = 'normal';
          this.terminalModeLabel = 'Normal Mode';
          this.updateTaxList();
        }
      }

    })
  }

  removeCustomer() {
    this.selectedCustomer = null;
  }

  addAndSelectCustomer(c: Customer) {
    this.customerService.createCustomer(c).subscribe(
      () => {
        // this.selectedCustomer = c;
        console.log('selectedCustomer:', c);
        this.selectCustomer(c);
      },
      (err) => {

        //handle error here
      });
  }

  priceVATInclusive(p: Product, terminalMode?: boolean): number {
    if (terminalMode) {
      const productTaxes = this.getTaxCodes(p);
      return taxHelpers.calculateTotalTax(p.price, productTaxes, p.rrp);
    }
    else
      return taxHelpers.calculateTotalTax(p.price, p.taxCodes, p.rrp);
  }


  private updateTaxList() {
    this.taxList = [];

    if (!this.purchasedItems || this.purchasedItems.length < 1) return;

    for (let i = 0; i < this.purchasedItems.length; i++) {
      // check terminal mode and add appropriate tax
      const productTaxes = this.getTaxCodes(this.purchasedItems[i].product);

      for (let j = 0; j < productTaxes.length; j++) {

        let taxAmount = roundTo(this.purchasedItems[i].quantity *
          taxHelpers.calculateTotalTax(
            this.purchasedItems[i].product.price,
            productTaxes,
            this.purchasedItems[i].product.rrp
          )
          , 2);
        const t = {
          id: productTaxes[j].id,
          code: productTaxes[j].code,
          name: this.taxes.find((x) => x.id === productTaxes[j].id).name,
          amount: taxAmount
          ,
          description: '',
          active: true,
          expiredDate: '',
          effectiveDate: ''
        };
        const tax = this.taxList.find((x) => x.id === t.id);
        if (tax) tax.amount += t.amount;
        else this.taxList.push(t);
      }
    }
  }


  private getTaxCodes(p: Product): Tax[] {
    let productTaxes: Tax[] = [];
    if (this.terminalMode === 'privilege') {
      console.log(`terminalMode:`, this.terminalMode);
      const t = this.taxes.find(x => x.name.toLowerCase() === 'privileged persons');
      if (t) productTaxes.push(t);
    }
    else if (this.terminalMode === 'export') {
      console.log(`terminalMode:`, this.terminalMode);
      const t = this.taxes.find(x => x.name.toLowerCase() === 'export');
      if (t) productTaxes.push(t);
    }
    else {
      productTaxes = p.taxCodes;
    }
    // sometimes a tax code could have been deleted - we don't want to return undefined in the array
    if (productTaxes.length < 1) productTaxes = p.taxCodes;
    return productTaxes;
  }

  private getESDRequest(): ESDSignInvoiceRequest {
    if (!this.esdStatus) return null;
    let items: Item[] = [];

    for (let i = 0; i < this.purchasedItems.length; i++) {
      let taxLabels: string[] = [];

      let rrp = this.purchasedItems[i].product.rrp ? this.purchasedItems[i].product.rrp : this.purchasedItems[i].product.price;
      console.log(`setting rrp to ${rrp}`);
      const taxCodes = this.getTaxCodes(this.purchasedItems[i].product);
      // some of the product tax codes may have changed based on the terminal mode
      this.purchasedItems[i].product.taxCodes = taxCodes;
      taxCodes.forEach(taxCode => {
        const t = this.taxes.find(x => x.id === taxCode.id);
        taxLabels.push(t.code);
      });
      const item: Item = {
        ItemId: i + 1,
        Description: this.purchasedItems[i].product.name,
        BarCode: this.purchasedItems[i].product.productCode,
        Quantity: this.purchasedItems[i].quantity,
        UnitPrice: this.purchasedItems[i].product.price,
        Discount: 0,
        TaxLabels: taxLabels,
        TotalAmount: (this.purchasedItems[i].product.price * this.purchasedItems[i].quantity),
        isTaxInclusive: false,
        RRP: rrp
      }
      items.push(item);
    }


    let esdRequest: ESDSignInvoiceRequest = {
      PosSerialNumber: this.esdStatus.SerialNumber,
      PosVendor: this.esdStatus.Manufacture,
      PosModel: this.esdStatus.Model,
      PosSoftVersion: this.esdStatus.SoftwareVersion,
      IssueTime: this.datePipe.transform(Date.now(), 'yyyyMMddHHmmss'),
      TransactionType: this.debitNoteMode ? 2 : 0,
      PaymentMode: 0,
      SaleType: 0,

      Cashier: this.userService.user.firstName,
      LocalPurchaseOrder: "",
      OriginalInvoiceCode: "",
      OriginalInvoiceNumber: "",
      Items: items
    }
    if (this.debitNoteMode) {

      const transactionDetails: SalesTransaction = JSON.parse(this.terminalParameters.transactionDetails);
      if (this.terminalParameters) { console.log(`terminal parameters present`) };
      if (transactionDetails) { console.log(`data present in transactionDetails:`, transactionDetails) };
      console.log(`Debit Note, original invoice number: ${transactionDetails.response.InvoiceNumber}, code: ${transactionDetails.response.InvoiceCode}`)
      esdRequest.OriginalInvoiceNumber = transactionDetails.response.InvoiceNumber;
      esdRequest.OriginalInvoiceCode = transactionDetails.response.InvoiceCode;
      esdRequest.Memo = this.debitNoteMemo.nativeElement.value;
    }

    if (this.selectedCustomer) {
      esdRequest.BuyerTPIN = this.selectedCustomer.TPIN;
      esdRequest.BuyerName = `${this.selectedCustomer.firstName} ${this.selectedCustomer.lastName}`;
      esdRequest.BuyerTaxAccountName = this.selectedCustomer.taxAccountName;
      esdRequest.BuyerTel = this.selectedCustomer.telephone;
      if (this.selectedCustomer.lpo) {
        esdRequest.LocalPurchaseOrder = this.selectedCustomer.lpo.toString();
      }
    }
    return esdRequest;
  }

  clearAll() {
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.data = {
      title: 'Clear All',
      description: `Are you sure you want to clear everything?`,
      okLabel: 'Clear',
      cancelLabel: 'Cancel'
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, this.dialogConfig);
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.purchasedItems = [];
        this.taxList = [];
        this.selectedCustomer = null;
        if (this.terminalMode === 'privilege') this.terminalMode = 'normal';
      }
    });
  }

  completeTransaction() {

    let tenderedAmount = 0;
    let changeDue = 0;

    this.dialogConfig.autoFocus = true;
    this.dialogConfig.disableClose = true;
    this.dialogConfig.data = {
      totalAmount: this.totalItems(), selectedCustomer: this.selectedCustomer
    };
    const dialogRef = this.dialog.open(CompleteSaleComponent, this.dialogConfig);

    dialogRef.componentInstance.completeTransaction
      .pipe(
        finalize(() => {
        })
      )
      .subscribe((tendered) => {

        if (tendered) {
          const esdRequest = this.getESDRequest();
          if (!esdRequest) {
            this.dialogConfig.data = { changeDue: 0, success: false, errorCode: 404, errorDescription: 'could not connect to ESD' };
            const transactionResult = this.dialog.open(TransactionResultComponent, this.dialogConfig);
            console.log('Error signing invoice:', 404);
            return;
          }
          let totalVAT = 0;
          esdRequest.PaymentMode = tendered.paymentMode;
          if (tendered.paymentMode === 4) {
            esdRequest.PaymentMode = 0;
            esdRequest.SaleType = 1;
          }

          // console.log('ESD Request:', JSON.stringify(esdRequest));

          // Sign Invoice through ESD
          this.esdService.signInvoice(esdRequest)
            .pipe(
              finalize(() => {
              })
            )
            .subscribe(result => {
              // Success signing invoice

              tenderedAmount = tendered.cashReceived;
              changeDue = tendered.cashReceived - result.TotalAmount;
              result.TaxItems.forEach(element => {
                totalVAT += element.TaxAmount;
              });
              console.log('Signing invoice complete');
              result.ESDTime = this.esdService.getEsdDateTime(result.ESDTime);

              const salesTransaction: SalesTransaction = {
                purchasedItems: this.purchasedItems,
                taxList: this.taxList,
                esd: this.esdStatus,
                response: result,
                saleType: esdRequest.SaleType,
                totalVAT: totalVAT,
                total: result.TotalAmount,
                customer: this.selectedCustomer,
                tendered: tenderedAmount,
                changeDue: changeDue,
                cashier: this.userService.user.firstName,
                isRefund: false,
                transactionType: this.debitNoteMode ? 2 : 0,
                paymentType: { id: tendered.paymentMode, name: esdHelpers.getPaymentType(tendered.paymentMode) },
                OriginalInvoiceNumber: esdRequest.OriginalInvoiceNumber,
                OriginalInvoiceCode: esdRequest.OriginalInvoiceCode
              };

              const sale: Sales = {
                invoiceNumber: result.InvoiceNumber,
                saleType: esdRequest.SaleType,
                customer: this.selectedCustomer,
                salesTotal: result.TotalAmount,
                productTotal: result.TotalAmount - totalVAT,
                taxTotal: totalVAT,
                transactionType: this.debitNoteMode ? 2 : 0,
                paymentType: tendered.paymentMode,
                receiptUrl: result.VerificationUrl,
                tendered: tendered.cashReceived,
                changeDue: tendered.cashReceived - result.TotalAmount,
                refundReason: this.debitNoteMode ? this.debitNoteMemo.nativeElement.value : '',
                transactionDetails: JSON.stringify(salesTransaction)
              }

              // update product quantities and record sale
              forkJoin([this.inventoryService.updateQuantities(this.purchasedItems),
              this.salesService.createSale(sale)]).subscribe(([quantities, sales]) => {
              });

              this.dialogConfig.data = { changeDue: tendered.cashReceived - result.TotalAmount, success: true };
              const transactionResult = this.dialog.open(TransactionResultComponent, this.dialogConfig);
              transactionResult.afterClosed().subscribe(() => {
                // setting refundReason and transaction type here to avoid saving duplicate data to db.
                salesTransaction.refundReason = sale.refundReason;
                salesTransaction.transactionType = sale.transactionType;

                this.purchaseList.emit(salesTransaction);
                this.purchasedItems = [];
                this.selectedCustomer = null;
                this.terminalMode = 'normal'; // always switch back to normal mode
                this.terminalParameters = null;
                this.debitNoteMode = false;
              }
              );
              dialogRef.close();
            },
              error => {
                // failture during invoice signing
                dialogRef.close();
                console.log('signing error object:', JSON.stringify(error));
                this.dialogConfig.data = { changeDue: tendered.cashReceived - this.totalItems(), success: false, errorCode: error.error.ErrorCode, errorDescription: error.error.Description };
                const transactionResult = this.dialog.open(TransactionResultComponent, this.dialogConfig);
                console.log('Error signing invoice:', error.error.ErrorCode);
              }
            );

        }
      })
  }
  applyFilter(event: Event) {
    console.log('applying filter');
    this.textFilter = (event.target as HTMLInputElement).value;
    this.filteredProducts = [];
    this.getProducts(0, this.paginator.pageSize, this.textFilter, this.categoryFilter);
  }

  newProduct() {
    this.dialogConfig.data = { product: null, taxList: this.taxes, categories: this.categories, customProduct: true };
    const dialogRef = this.dialog.open(
      EditProductDialogComponent,
      this.dialogConfig
    );
    dialogRef.componentInstance.saveData.subscribe((data) => {
      if (data) {
        this.customProductId += 1;
        data.id = this.customProductId;
        this.addItem(data, data.quantity);
        dialogRef.close();
      }
    });
  }

  async setTerminalMode(mode: string) {
    this.terminalMode = mode;
    switch (this.terminalMode) {
      case 'privilege': {
        this.terminalModeLabel = 'Privileged Persons Mode';
        this.selectCustomer();
        break;
      }
      case 'export': { this.terminalModeLabel = 'Export Mode'; break; }
      case 'normal': { this.terminalModeLabel = 'Normal Mode'; break; }
    }

    this.updateTaxList();
    //this.totalItems();
  }

  setDebitNote(x: boolean) {
    this.debitNoteMode = x;
  }

  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key.length !== 1) return; // ignore special characters
    const now = new Date().getTime();

    if (now - this.barCodeTime < scanner.keySpeed) {
      this.barCodeValue += event.key;
      if (!this.scanning) {
        this.scanning = true;
        setTimeout(() => {
          this.scanItem();
        }, scanner.scanDelay);
      }
    }
    else {
      this.barCodeValue = event.key;
    }
    this.barCodeTime = now;
  }

  scanItem() {
    console.log('scanItem');
    if (this.barCodeValue.length < scanner.minBarcodeLength) {
      this.scanning = false;
      return;
    }
    this.inventoryService.getProducts(this.barCodeValue).subscribe(result => {
      if (result.length > 0) {
        this.addItem(result[0]);
        this.input.nativeElement.value = "";
      }
      else {
        this.snackbarService.showLongErrorMessage('Error: No matching product barcode found');
      }
      this.barCodeValue = "";
    },
      err => {
        console.log('scanner error:', err);
      },
      () => {
        console.log(`setting scanning to false`);
        this.scanning = false;
      });

  }

  previousPage() {
    this.paginator.previousPage();
  }

  nextPage() {
    this.paginator.nextPage();
  }


}
