import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { User } from '../models/user';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { WINDOW } from '../window';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl: string;
  redirectUrl: string;
  constructor(
    @Inject(WINDOW) private window: Window,
    protected httpClient: HttpClient,
    private router: Router) {
    this.apiUrl = `${environment.protocol}${this.window.location.hostname}:${environment.port}${environment.apiUrl}`;
  }

  signIn(username: string, password: string) {
    const user = { username: username, password: password };
    return this.httpClient.post(`${this.apiUrl}/login`, user);
  }

  signOut() {
    this.httpClient.post(`${this.apiUrl}/logout`, {}).subscribe(result => {
      localStorage.removeItem('user');
      this.router.navigate(['']);
    },
      err => {
        console.log('could not log out,error:', err);
      });
  }

  isLoggedIn() {
    const user = localStorage.getItem('user');
    return user ? true : false;
  }


}


