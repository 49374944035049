import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Role } from 'src/app/models/role';
import { UserService } from 'src/app/services/user.service';
import { User } from '../../../models/user';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { EditUserDialogComponent } from '../dialogs/edit-user-dialog/edit-user-dialog.component';
import { SnackbarService } from '../../../services/snackbar.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;

  roleList = [{ name: "Supervisor" }, { name: "Cashier" }];
  dataSource: any;
  displayedColumns: string[] = [];
  currentUser: User;
  private dialogConfig: MatDialogConfig;
  constructor(
    private userService: UserService,
    private dialog: MatDialog,
    private snackbarService: SnackbarService
  ) { }

  ngOnInit(): void {
    this.displayedColumns = [
      'firstName',
      'lastName',
      'username',
      'email',
      'roles',
      'changePassword',
      'actions'
    ];

    this.currentUser = this.userService.getUser();

    if (this.userService.isAdmin()) this.roleList.push({ name: "Admin" });
    this.userService.getAllUsers().subscribe(result => {
      this.dataSource = new MatTableDataSource(result);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
    this.dialogConfig = new MatDialogConfig();
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.maxHeight = '85vh';
  }


  addItem() {

    console.log('adding...');
    this.dialogConfig.data = { user: null, roleList: this.roleList };
    const dialogRef = this.dialog.open(EditUserDialogComponent, this.dialogConfig);
    dialogRef.componentInstance.saveData.subscribe(data => {
      if (data) {
        this.userService.createUser(data).subscribe(
          () => {
            dialogRef.close();
            this.snackbarService.showSuccessMessage('User added');
            this.refreshData();
            //this.table.renderRows();
          },
          (err) => {
            console.log(`error:`, err);
            let msg = '';
            if (err.error[0].errorCode === 19) {
              msg = 'Username or email already exists';
            }
            else {
              msg = 'Could not save User';
            }
            this.snackbarService.showErrorMessage(msg);
          }
        );
      }
    });
  }

  editItem(item: User, changePassword?: boolean) {
    const r = item.roles.find(x => x.name === 'Admin');
    if (r && !this.userService.isAdmin()) {
      this.snackbarService.showErrorMessage('You do not have permission to edit this user');
      return;
    }

    this.dialogConfig.data = { user: item, roleList: this.roleList, changePassword: changePassword };
    const dialogRef = this.dialog.open(EditUserDialogComponent, this.dialogConfig);
    dialogRef.componentInstance.saveData.subscribe((data) => {
      if (data) {
        this.userService.updateUser(item.id, data).subscribe(
          () => {
            dialogRef.close();
            this.snackbarService.showSuccessMessage('Category edited successfully');
            this.refreshData();
          },
          (err) => {
            console.log(`error:`, err);
            let msg = '';
            if (err.error[0].errorCode === 19) {
              msg = 'Username or email already exists';
            }
            else {
              msg = 'Could not save Category';
            }
            this.snackbarService.showErrorMessage(msg);
          }
        );
      }
    });
  }

  changePassword(item: User) {
    const r = item.roles.find(x => x.name === 'Admin');
    if (r && !this.userService.isAdmin()) {
      this.snackbarService.showErrorMessage('You do not have permission to change this password');
      return;
    }
    this.editItem(item, true);
  }

  deleteItem(item: User) {
    const r = item.roles.find(x => x.name === 'Admin');
    if (r && !this.userService.isAdmin()) {
      this.snackbarService.showErrorMessage('You do not have permission to delete this user');
      return;
    }
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.data = {
      title: 'Delete Category',
      description: `Are you sure you want to delete user "${item.firstName} ${item.lastName}"?`,
      okLabel: 'Delete',
      cancelLabel: 'Cancel'
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, this.dialogConfig);
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.userService.deleteUser(item.id).subscribe(
          () => {
            this.snackbarService.showSuccessMessage('User deleted');
            this.refreshData();
          },
          err => {
            this.snackbarService.showErrorMessage('Error: could not delete user');
          }
        );
      }
    });

  }

  refreshData() {
    this.userService.getAllUsers().subscribe(
      (data) => {
        this.dataSource.data = data;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    );
  }

  getRoles(roles: Role[]): string {
    var givenRoles = '';
    roles.forEach(element => {
      givenRoles += element.name + " ";
    });
    return givenRoles;
  }

}
