<div class="container mat-elevation-z8 main-container">

    <h2 fxLayoutAlign="start end">ESD Settings</h2>
    <div fxLayout="row" *ngIf="esdConfigError!=''" class="mat-error">
        <mat-icon fxFlex="5">warning</mat-icon>
        <h3 fxFlex>{{esdConfigError}}</h3>

    </div>

    <form [formGroup]="esdForm">
        <div fxLayout="column" class="esd-config">
            <div fxLayout="row" fxLayoutAlign="start center">
                <b fxFlex="25">Configuration Data</b>
                <mat-form-field fxFlex="15">
                    <input matInput placeholder="PoS SerialNumber" formControlName="PosSerialNumber">
                    <mat-error *ngFor="let errorMessage of esdForm.controls.PosSerialNumber['errorMessages']">
                        {{errorMessage}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex="15" fxFlexOffset="5">
                    <input matInput placeholder="PoS Vendor" formControlName="PosVendor">
                    <mat-error *ngFor="let errorMessage of esdForm.controls.PosVendor['errorMessages']">
                        {{errorMessage}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex="15" fxFlexOffset="5">
                    <input matInput placeholder="PoS Model" formControlName="PosModel">
                    <mat-error *ngFor="let errorMessage of esdForm.controls.PosModel['errorMessages']">
                        {{errorMessage}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex="15" fxFlexOffset="5">
                    <input matInput placeholder="PoS Software Version" formControlName="PosSoftVersion">
                    <mat-error *ngFor="let errorMessage of esdForm.controls.PosSoftVersion['errorMessages']">
                        {{errorMessage}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
                <b fxFlex="25">Network</b>
                <mat-form-field fxFlex="15">
                    <input matInput placeholder="IP Address" formControlName="IP">
                    <mat-error *ngFor="let errorMessage of esdForm.controls.IP['errorMessages']">
                        {{errorMessage}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex="15" fxFlexOffset="5">
                    <input matInput placeholder="Port" formControlName="Port">
                    <mat-error *ngFor="let errorMessage of esdForm.controls.Port['errorMessages']">
                        {{errorMessage}}
                    </mat-error>
                </mat-form-field>
            </div>

        </div>
        <br>
        <button mat-raised-button color="accent" (click)="saveESDConfiguration()" fxLayoutAlign="start end">
            <mat-icon>done</mat-icon>
            Save Configuration
        </button>
    </form>
    <br>

    <div *ngIf="esd">
        <h2>ESD Information</h2>
        <mat-tab-group>
            <mat-tab label="Device">
                <mat-list>
                    <mat-list-item>
                        <div fxFlex="30">
                            Hardware Version
                        </div>
                        <div fxFlex="40">
                            {{esd?.HardwareVersion}}
                        </div>
                    </mat-list-item>
                    <mat-list-item>
                        <div fxFlex="30">
                            Software Version
                        </div>
                        <div fxFlex="40">
                            {{esd?.SoftwareVersion}}
                        </div>
                    </mat-list-item>
                    <mat-list-item>
                        <div fxFlex="30">
                            Serial Number
                        </div>
                        <div fxFlex="40">
                            {{esd?.SerialNumber}}
                        </div>
                    </mat-list-item>
                    <mat-list-item>
                        <div fxFlex="30">
                            Manufacturer
                        </div>
                        <div fxFlex="40">
                            {{esd?.Manufacture}}
                        </div>
                    </mat-list-item>
                    <mat-list-item>
                        <div fxFlex="30">
                            Model
                        </div>
                        <div fxFlex="40">
                            {{esd?.Model}}
                        </div>
                    </mat-list-item>
                    <mat-list-item>
                        <div fxFlex="30">
                            TPIN
                        </div>
                        <div fxFlex="40">
                            {{esd?.TPIN}}
                        </div>
                    </mat-list-item>
                    <mat-list-item>
                        <div fxFlex="30">
                            Taxpayer Name
                        </div>
                        <div fxFlex="40">
                            {{esd?.TaxpayerName}}
                        </div>
                    </mat-list-item>
                    <mat-list-item>
                        <div fxFlex="30">
                            Address
                        </div>
                        <div fxFlex="40">
                            {{esd?.Addreess}}
                        </div>
                    </mat-list-item>
                    <mat-list-item>
                        <div fxFlex="30">
                            ESDTime
                        </div>
                        <div fxFlex="40">
                            {{esdTime}}
                        </div>
                    </mat-list-item>
                    <mat-list-item>
                        <div fxFlex="30">
                            Terminal Id
                        </div>
                        <div fxFlex="40">
                            {{esd?.TerminalID}}
                        </div>
                    </mat-list-item>
                    <mat-list-item>
                        <div fxFlex="30">
                            Initialized
                        </div>
                        <div fxFlex="40">
                            <mat-slide-toggle color="primary" checked="{{esd?.isInitialized}}" disabled>
                            </mat-slide-toggle>

                        </div>
                    </mat-list-item>
                    <mat-list-item>
                        <div fxFlex="30">
                            Locked
                        </div>
                        <div fxFlex="40">
                            <mat-slide-toggle color="primary" checked="{{esd?.isLocked}}" disabled></mat-slide-toggle>

                        </div>
                    </mat-list-item>

                </mat-list>
            </mat-tab>
            <mat-tab label="Monitoring">
                <mat-list>
                    <mat-list-item>
                        <div fxFlex="30">
                            Offline Number
                        </div>
                        <div fxFlex="40">
                            {{esd?.MonitoringInfo.OfflineNum}}
                        </div>
                    </mat-list-item>
                    <mat-list-item>
                        <div fxFlex="30">
                            Single Amount
                        </div>
                        <div fxFlex="40">
                            {{esd?.MonitoringInfo.SingleAmount}}
                        </div>
                    </mat-list-item>
                    <mat-list-item>
                        <div fxFlex="30">
                            Monthly Invoice Quantity
                        </div>
                        <div fxFlex="40">
                            {{esd?.MonitoringInfo.MonthlyInvoiceQuantity}}
                        </div>
                    </mat-list-item>
                    <mat-list-item>
                        <div fxFlex="30">
                            Monthly Credit Note Amount
                        </div>
                        <div fxFlex="40">
                            {{esd?.MonitoringInfo.MonthlyCreditNoteAmount}}
                        </div>
                    </mat-list-item>
                    <mat-list-item>
                        <div fxFlex="30">
                            Invoice Holding Quantity
                        </div>
                        <div fxFlex="40">
                            {{esd?.MonitoringInfo.InvoiceHoldingQuantity}}
                        </div>
                    </mat-list-item>
                    <mat-list-item>
                        <div fxFlex="30">
                            Remaining Threshold
                        </div>
                        <div fxFlex="40">
                            {{esd?.MonitoringInfo.RemainThreshold}}
                        </div>
                    </mat-list-item>
                    <mat-list-item>
                        <div fxFlex="30">
                            Monthly Credit Note Number
                        </div>
                        <div fxFlex="40">
                            {{esd?.MonitoringInfo.MonthlyCreditNoteNum}}
                        </div>
                    </mat-list-item>
                </mat-list>
            </mat-tab>
            <!-- <mat-tab label="Tax">
            <mat-list>
                <div *ngFor="let t of esd?.TaxInfo">
                    <mat-list-item>
                        <div fxFlex="30">
                            Tax Type
                        </div>
                        <div fxFlex="40">
                            {{t.TaxType}}
                        </div>
                    </mat-list-item>
                    <div *ngFor="let c of t.Category" class="category">
                        <mat-list-item class="tax-items">
                            <div fxFlex="30">
                                Category Id
                            </div>
                            <div fxFlex="40">
                                {{c.CategoryId}}
                            </div>
                        </mat-list-item>
                        <mat-list-item class="tax-items">
                            <div fxLayout="row" fxFlex="30">
                                Tax Label
                            </div>
                            <div fxFlex="40">
                                {{c.TaxLabel}}
                            </div>
                        </mat-list-item>
                        <mat-list-item class="tax-items">
                            <div fxLayout="row" fxFlex="30">
                                Tax Name
                            </div>
                            <div fxFlex="40">
                                {{c.TaxName}}
                            </div>
                        </mat-list-item>
                        <mat-list-item class="tax-items">
                            <div fxLayout="row" fxFlex="30">
                                Tax Rate
                            </div>
                            <div fxFlex="40">
                                {{c.TaxRate}}
                            </div>
                        </mat-list-item>
                        <mat-list-item class="tax-items">
                            <div fxLayout="row" fxFlex="30">
                                Effective Date
                            </div>
                            <div fxFlex="40">
                                {{c.EffectiveDate}}
                            </div>
                        </mat-list-item>
                        <mat-list-item class="tax-items">
                            <div fxLayout="row" fxFlex="30">
                                Expired Date
                            </div>
                            <div fxFlex="40">
                                {{c.ExpiredDate}}
                            </div>
                        </mat-list-item>

                    </div>
                </div>

            </mat-list>
        </mat-tab> -->

            <mat-tab label="Tax">
                <div *ngFor="let t of esd?.TaxInfo">
                    <div class="tax-type">
                        TaxType:{{t.TaxType}}
                    </div>

                    <mat-table [dataSource]="t.Category">
                        <ng-container matColumnDef="id">
                            <mat-header-cell fxFlex="10" *matHeaderCellDef>Id</mat-header-cell>
                            <mat-cell fxFlex="10" *matCellDef="let row" data-label="CategoryId"> {{row.CategoryId}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="label">
                            <mat-header-cell fxFlex="10" *matHeaderCellDef> Label </mat-header-cell>
                            <mat-cell fxFlex="10" *matCellDef="let row" data-label="label"> {{row.TaxLabel}}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="name">
                            <mat-header-cell fxFlex="20" *matHeaderCellDef> Name </mat-header-cell>
                            <mat-cell fxFlex="20" *matCellDef="let row" data-label="TaxName"> {{row.TaxName}}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="rate">
                            <mat-header-cell fxFlex="10" *matHeaderCellDef> Rate </mat-header-cell>
                            <mat-cell fxFlex="10" *matCellDef="let row" data-label="TaxRate"> {{row.TaxRate}}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="effectiveDate">
                            <mat-header-cell fxFlex="25" *matHeaderCellDef> Effective Date
                            </mat-header-cell>
                            <mat-cell fxFlex="25" *matCellDef="let row" data-label="EffectiveDate">
                                {{row.EffectiveDate}}
                            </mat-cell>
                        </ng-container>


                        <ng-container matColumnDef="expiredDate">
                            <mat-header-cell fxFlex="25" *matHeaderCellDef> Expired Date </mat-header-cell>
                            <mat-cell fxFlex="25" *matCellDef="let row" data-label="ExpiredDate"> {{row.ExpiredDate}}
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;">
                        </mat-row>
                    </mat-table>
                </div>
            </mat-tab>



        </mat-tab-group>
    </div>
</div>