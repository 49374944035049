import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReactiveFormConfig, RxFormBuilder } from '@rxweb/reactive-form-validators';
import { BehaviorSubject, Observable } from 'rxjs';
import { Category, Tax } from 'src/app/models/inventory';
import { InventoryService } from 'src/app/services/inventory.service';

@Component({
  selector: 'app-edit-category-dialog',
  templateUrl: './edit-category-dialog.component.html',
  styleUrls: ['./edit-category-dialog.component.scss'],
})
export class EditCategoryDialogComponent implements OnInit {
  title: string;
  form: FormGroup;
  categoryData: Category;
  taxList: Tax[];
  saveData: BehaviorSubject<Category>;

  constructor(
    private fb: RxFormBuilder,
    private dialogRef: MatDialogRef<EditCategoryDialogComponent>,
    private inventoryService: InventoryService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.saveData = new BehaviorSubject<Category>(null);
    this.taxList = data.taxList;

    if (data.category) {
      this.categoryData = data.category;
      this.title = 'Edit Category';
    } else {
      this.title = 'Add Category';
      this.categoryData = <Category>{};
    }
  }

  ngOnInit(): void {
    ReactiveFormConfig.set({
      "validationMessage": {
        "required": "This field is required"
      }
    });
    this.form = this.fb.group({
      name: [this.categoryData.name, Validators.required],
      description: [this.categoryData.description],
      taxCodes: [null]
    });

    // we need to set the default values to the multiselect drop down here
    this.form.controls['taxCodes'].setValue(this.categoryData.taxCodes);

  }

  close() {
    this.dialogRef.close();
  }

  save() {

    if (this.form.valid) {
      let c = <Category>{};
      c.name = this.form.controls['name'].value;
      c.description = this.form.controls['description'].value;
      c.taxCodes = this.form.controls['taxCodes'].value;
      this.saveData.next(c);

      //this.dialogRef.close(this.categoryData);
    }
    else {
      this.form.markAllAsTouched();
    }
  }

  // This is required for comparing objects on the multiple select control
  compareFunction(o1: any, o2: any) {
    if (!o1 || !o2) return;
    return (o1.id == o2.id);
  }
}
