export function getPaymentType(mode: number): string {
    switch (mode) {
        case 0: return "Cash";

        case 1: return "Credit";

        case 2: return "Cheque";

        case 3: return "Electronic Transfer";

    }
}
