<h2 mat-dialog-title>Select Customer</h2>

<mat-dialog-content [formGroup]="form">

    <div fxLayout="column">
        <mat-form-field>
            <input type="text" placeholder="Search for customer" aria-label="Number" matInput fxFlex="50"
                name="selectCustomer" formControlName="selectCustomer" [matAutocomplete]="auto">
            <mat-error *ngFor="let errorMessage of form.controls.selectCustomer['errorMessages']">
                {{errorMessage}}
            </mat-error>
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let option of customerList" [value]="option">
                    {{option.firstName}} {{option.lastName}} {{option.companyName}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
    <div *ngIf="terminalMode==='privilege'" fxLayout="column">
        <mat-form-field>
            <input type="number" fxFlex matInput placeholder="Local Purchase Order Number" formControlName="lpo"
                name="lpo" required>
            <mat-error *ngFor="let errorMessage of form.controls.lpo['errorMessages']">
                {{errorMessage}}
            </mat-error>
        </mat-form-field>
    </div>


</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <div fxLayoutAlign="start center" fxFlex="35" (click)="createNewCustomer()">

        <button mat-raised-button color="accent" (click)="close()">
            <mat-icon>add</mat-icon>New Customer
        </button>
    </div>
    <div fxLayoutAlign="end center" fxFlex="65">
        <button mat-button color="warn" (click)="close()">Cancel</button>
        <button mat-raised-button color="primary" (click)="select()">Select</button>
    </div>
</mat-dialog-actions>