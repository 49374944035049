import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
import { IsLoadingService } from '@service-work/is-loading';

@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.scss']
})
export class AdminPanelComponent {

  isLoading: Observable<boolean>;
  title: string;
  menuItems = [
    {
      name: 'Dashboard',
      icon: 'dashboard',
      route: '/admin/dashboard',
      visible: true
    },
    {
      name: 'Users',
      icon: 'people',
      route: '/admin/users',
      visible: true
    },
    {
      name: 'Products',
      icon: 'shopping_cart',
      route: '/admin/products',
      visible: true
    },
    {
      name: 'Categories',
      icon: 'category',
      route: '/admin/categories',
      visible: true
    },

    {
      name: 'Taxes',
      icon: 'calculate',
      route: '/admin/taxes',
      visible: true
    },
    {
      name: 'Customers',
      icon: 'emoji_people',
      route: '/admin/customers',
      visible: true
    },
    {
      name: 'ESD',
      icon: 'storage',
      route: '/admin/esd',
      visible: this.userService.isAdmin()
    },
    {
      name: 'Printing',
      icon: 'print',
      route: '/admin/print',
      visible: true
    }
  ]

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private isLoadingService: IsLoadingService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.isLoading = this.isLoadingService.isLoading$();
  }

}
