<div class="add-button">
    <button mat-raised-button color="accent" (click)="addItem()">
        <mat-icon>add</mat-icon>
        Add User
    </button>
</div>
<div class=" mt-2 mat-elevation-z8">
    <mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="firstName">
            <mat-header-cell fxFlex="15" *matHeaderCellDef mat-sort-header> First Name </mat-header-cell>
            <mat-cell fxFlex="15" *matCellDef="let row" data-label="firstName"> {{row.firstName}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastName">
            <mat-header-cell fxFlex="15" *matHeaderCellDef mat-sort-header> Last Name </mat-header-cell>
            <mat-cell fxFlex="15" *matCellDef="let row" data-label="lastName"> {{row.lastName}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="username">
            <mat-header-cell fxFlex="15" *matHeaderCellDef mat-sort-header> Username </mat-header-cell>
            <mat-cell fxFlex="15" *matCellDef="let row" [style.color]="row.color" data-label="username">
                {{row.username}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="email">
            <mat-header-cell fxFlex="25" *matHeaderCellDef mat-sort-header> Email </mat-header-cell>
            <mat-cell fxFlex="25" *matCellDef="let row" [style.color]="row.color" data-label="email">
                {{row.email}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="roles">
            <mat-header-cell fxFlex="10" *matHeaderCellDef mat-sort-header> Roles </mat-header-cell>
            <mat-cell fxFlex="10" *matCellDef="let row" [style.color]="row.color" data-label="roles">
                {{getRoles(row.roles)}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="changePassword">
            <mat-header-cell fxFlex="10" *matHeaderCellDef mat-sort-header> Password </mat-header-cell>
            <mat-cell fxFlex="10" *matCellDef="let row" [style.color]="row.color" data-label="roles">
                <button mat-icon-button color="primary" (click)="changePassword(row)">
                    <mat-icon aria-label="Edit">lock_open</mat-icon>
                    <small>Change</small>
                </button>
            </mat-cell>
        </ng-container>


        <!-- actions -->
        <ng-container fxFlex="14" matColumnDef="actions">
            <mat-header-cell fxLayoutAlign="end center" *matHeaderCellDef>

            </mat-header-cell>
            <mat-cell fxFlex="14" fxLayoutAlign="end center" *matCellDef="let row; let i=index;">
                <button mat-icon-button color="primary" (click)="editItem(row)">
                    <mat-icon aria-label="Edit">edit</mat-icon>
                </button>

                <button mat-icon-button color="basic" (click)="deleteItem(row)">
                    <mat-icon aria-label="Delete">delete</mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;">
        </mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
</div>